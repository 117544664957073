import SliderHome from "../../components/sliderHome/SliderHome";
import TechBlock from "../../components/techBlock/TechBlock";
import InfluencerMarketing from "../../components/influncerMarkrtingBlog/InfluencerMarketing";
import HomeServiceBlock from "../../components/homeServiceBlock/HomeServiceBlock";
import BrandBlock from "../../components/brandBlock/BrandBlock";
import HomeCtg from "../../components/homectg/HomeCtg";
import HomeCityBlock from "../../components/homeCItyBlock/HomeCityBlock";
import YouTuberBlock from "../../components/youtuberBlock/YouTuberBlock";
import InstagramBlock from "../../components/instagramBlock/InstagramBlock";
import MicroInfluencer from "../../components/microInfluencer/MicroInfluencer";
import HowItsWorkBlock from "../../components/howItsWorkBlock/HowItsWorkBlock";
import Header from "../../common/header/Header";
import Footer from '../../common/footer/Footer'
import FreqAskQues from "../../components/FreqAskQues";
function Homes() {
 
  return (
    <>
      <Header />
      <SliderHome />
      <TechBlock />
      <InfluencerMarketing />
      <HomeServiceBlock />
      <BrandBlock />
      <HomeCtg />
      <HomeCityBlock />
      <YouTuberBlock />
      <InstagramBlock />
      <MicroInfluencer />
      <HowItsWorkBlock />
      <FreqAskQues />
      <Footer/>
    </>
  );
}
export default Homes;
