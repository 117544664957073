import React from "react";
import "./HomeCityBlock.css";
function HomeCityBlock() {
  return (
    <>
      <div className="homecity">
        <div className="homecitymainwapper">
          <h2>Micro-Influencers in India</h2>
          <p>
            Search and Find top micro-influencers across top cities in India.
            All these content creators are registered members of Abaris
            Influencer Marketing.
          </p>
          <div className="innercityholder">
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/3907ba6d1c833ce9bc35b0244fd7b3c0.jpg"
                  alt="cityimg"
                  style={{ width: 143, height: 143 }}
                />
                <h3>DELHI</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/6b77cdffeaa08fc6730180748d2674ab.jpg"
                  alt="cityimg"
                  style={{ width: 143, height: 143 }}
                />
                <h3>MUMBAI</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/f7c67144b1bf29a69b62b2be0622552c.jpg"
                  alt="cityimg"
                  style={{ width: 143, height: 143 }}
                />
                <h3>BANGALORE</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/2d87a2bad970737da89c2545fc9b96c0.jpg"
                  alt="cityimg"
                  style={{ width: 143, height: 143 }}
                />
                <h3>HYDERABAD</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/c4a88ca252658a0ba1052c8193022239.jpg"
                  alt="cityimg"
                  style={{ width: 143, height: 143 }}
                />
                <h3>CHENNAI</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/f5c3de33c6e354a873b6dc2004507086.jpg"
                  alt="cityimg"
                  style={{ width: 143, height: 143 }}
                />
                <h3>PUNE</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/a903cc8334c236333aaa5b2b512746e2.jpg"
                  alt="cityimg"
                  style={{ width: 143, height: 143 }}
                />
                <h3>KOLKATA</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/e1c559c849b39e0f34f5b9be004680ee.jpg"
                  alt="cityimg"
                  style={{ width: 143, height: 143 }}
                />
                <h3>AHMEDABAD</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/113981b996fa90f150a90a0b24812fc1.jpg"
                  alt="cityimg"
                  style={{ width: 143, height: 143 }}
                />
                <h3>JAIPUR</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/36b2ef23d92cf82068ef49182e328798.jpg"
                  alt="cityimg"
                  style={{ width: 143, height: 143 }}
                />
                <h3>GURGAON</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/70c772cf9ede62402bc1d25086771c73.jpg"
                  alt="cityimg"
                  style={{ width: 143, height: 143 }}
                />
                <h3>BHUBANESWAR</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/d4d0170bd9fc99099b8202587ec19a2e.jpg"
                  alt="cityimg"
                  style={{ width: 143, height: 143 }}
                />
                <h3>INDORE</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/c4bd46e19c621c4ae9a0a344937f9ba7.jpg"
                  alt="cityimg"
                  style={{ width: 143, height: 143 }}
                />
                <h3>BHOPAL</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/e07545ee14a76b19b8e1be1890ac54e0.jpg"
                  alt="cityimg"
                  style={{ width: 143, height: 143 }}
                />
                <h3>LUCKNOW</h3>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default HomeCityBlock;
