import React from "react";
import "../brandBlock/BrandBlock.css";
function BrandBlock() {
  return (
    <>
      <div className="brandblock">
        <div className="brandblockmainwapper">
          <h2>Worked with Brands</h2>
          <ul>
            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/79b33225cf6f0ef0945a8a19cd209131.png"
                alt="flipkart"
                style={{ width: 178, height: 98 }}
              />
            </li>

            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/5bd857413e42b3aaa40965073a40a93d.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>

            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/8e71bb8fb1655aacd10294f78f7924ed.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>

            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/0efb4d2175a461a7006cca9fa2c65599.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>

            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/ea14334910b16c7ebb8dfefde23a5a94.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>

            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/250f711c8130e5941b8c65f285b72409.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>

            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/08121a756924f9ebe24054e0b7cfd69d.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>
            
            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/08121a756924f9ebe24054e0b7cfd69d.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
export default BrandBlock;
