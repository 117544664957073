const RecentPostData = [
    {
        imgSrc: "https://www.influglue.com/files/smacrawl/instagram/3710/3233705327428613130.jpg",
        likes: "1.8K",
        comments: "67"
    },
    {
        imgSrc: "https://www.influglue.com/files/smacrawl/instagram/3710/3083696208816142805.jpg",
        likes: "1.8K",
        comments: "67"
    },
    {
        imgSrc: "https://www.influglue.com/files/smacrawl/instagram/3710/3083696208816142805.jpg",
        likes: "1.8K",
        comments: "67"
    }, {
        imgSrc: "https://www.influglue.com/files/smacrawl/instagram/3710/3083696208816142805.jpg",
        likes: "1.8K",
        comments: "67"
    }, {
        imgSrc: "https://www.influglue.com/files/smacrawl/instagram/3710/3083696208816142805.jpg",
        likes: "1.8K",
        comments: "67"
    }, {
        imgSrc: "https://www.influglue.com/files/smacrawl/instagram/3710/3083696208816142805.jpg",
        likes: "1.8K",
        comments: "67"
    }, {
        imgSrc: "https://www.influglue.com/files/smacrawl/instagram/3710/3083696208816142805.jpg",
        likes: "1.8K",
        comments: "67"
    }, {
        imgSrc: "https://www.influglue.com/files/smacrawl/instagram/3710/3083696208816142805.jpg",
        likes: "1.8K",
        comments: "67"
    }, {
        imgSrc: "https://www.influglue.com/files/smacrawl/instagram/3710/3083696208816142805.jpg",
        likes: "1.8K",
        comments: "67"
    }, {
        imgSrc: "https://www.influglue.com/files/smacrawl/instagram/3710/3083696208816142805.jpg",
        likes: "1.8K",
        comments: "67"
    },
];
export default RecentPostData