import React from "react";
import "../faq/Faq.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
function Faq() {
  const [masterFdata, setMasterFdata] = useState();

  useEffect(() => {
    const getfaqData = async () => {
      const reqfData = await axios.get(
        "https://onlineparttimejobs.in/api/faqsMaster",
        {
          withCredentials: true,
        }
      );
      console.log(reqfData.data);
      setMasterFdata(reqfData.data);
    };
    getfaqData();
  }, []);
  return (
    <>
      <div className="faq">
        <div className="breadcurmb">
          <div className="mainwapperabout">
            <a href="/">Home</a>
            <span>»</span>
            FAQ
          </div>
        </div>
        <div className="mainloginholder">
          <div className="mainwappermainloginholder">
            <div className="landingSingupholder">
              <h1>Frequently Asked Questions</h1>
              <ul className="signuplist">
                {masterFdata?.map((item) => {
                  return (
                    <li>
                      <Link to={`influencer/${item._id}`}>
                        <div className="iconHolder">
                          <img
                            className=""
                            src="https://www.influglue.com/public/frontend/images/influencericon.png?v=1688734414"
                            alt="icon"
                            style={{ width: 64, height: 64 }}
                          />
                        </div>
                        <div className="textblock">
                          <h3>{item.title}</h3>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
              ;
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Faq;

// import React from "react";
// import "./Faq.css";
// import Header from "../../common/header/Header";
// import Footer from "../../common/footer/Footer";
// function Faq() {
//   return (
//     <>
//     <Header/>
//       <div className="faq">
//         <div className="breadcurmb">
//           <div className="mainwapperabout">
//             <a href="//">Home</a>
//             <span>»</span>
//             FAQ
//           </div>
//         </div>
//         <div className="mainloginholder">
//           <div className="mainwappermainloginholder">
//             <div className="landingSingupholder">
//               <h1>Frequently Asked Questions</h1>
//               <ul className="signuplist">
//                 <li>
//                   <a href="//">
//                     <div className="iconHolder">
//                       <img
//                         className=""
//                         src="https://www.influglue.com/public/frontend/images/influencericon.png?v=1688734414"
//                         alt="icon"
//                         style={{ width: 64, height: 64 }}
//                       />
//                     </div>
//                     <div className="textblock">
//                       <h3>For Influencers</h3>
//                     </div>
//                   </a>
//                 </li>
//                 <li>
//                   <a href="//">
//                     <div className="iconHolder">
//                       <img
//                         className=""
//                         src="https://www.influglue.com/public/frontend/images/advertiser-icon.png?v=1688734414"
//                         alt="icon"
//                         style={{ width: 64, height: 64 }}
//                       />
//                     </div>
//                     <div className="textblock">
//                       <h3>For Advertisers</h3>
//                     </div>
//                   </a>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer/>
//     </>
//   );
// }
// export default Faq;
