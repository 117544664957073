const DailyStateTableData=[
    {
        Date:'2023-11-11',
        Likes:'Hidden',	
        Comments:0,	
        Posts:1,	
        Videos:0,	
        Total:1
    }, {
        Date:'2023-10-11',
        Likes:456,	
        Comments:0,	
        Posts:1,	
        Videos:0,	
        Total:1
    }, {
        Date:'2023-8-14',
        Likes:'Hidden',	
        Comments:0,	
        Posts:1,	
        Videos:0,	
        Total:1
    }, {
        Date:'2023-7-18',
        Likes:1122,	
        Comments:0,	
        Posts:1,	
        Videos:0,	
        Total:1
    }, {
        Date:'2023-11-11',
        Likes:'Hidden',	
        Comments:0,	
        Posts:1,	
        Videos:0,	
        Total:1
    }, {
        Date:'2023-12-21',
        Likes:'Hidden',	
        Comments:0,	
        Posts:1,	
        Videos:0,	
        Total:1
    }, {
        Date:'2023-11-11',
        Likes:67,	
        Comments:0,	
        Posts:1,	
        Videos:0,	
        Total:1
    }, {
        Date:'2023-11-11',
        Likes:'Hidden',	
        Comments:0,	
        Posts:1,	
        Videos:0,	
        Total:1
    }, {
        Date:'2023-11-11',
        Likes:'Hidden',	
        Comments:0,	
        Posts:1,	
        Videos:0,	
        Total:1
    }, {
        Date:'2023-11-11',
        Likes:678,	
        Comments:0,	
        Posts:1,	
        Videos:0,	
        Total:1
    }, {
        Date:'2023-11-11',
        Likes:'Hidden',	
        Comments:0,	
        Posts:1,	
        Videos:0,	
        Total:1
    }, {
        Date:'2023-11-11',
        Likes:77,	
        Comments:0,	
        Posts:1,	
        Videos:0,	
        Total:1
    }, {
        Date:'2023-11-11',
        Likes:34,	
        Comments:0,	
        Posts:1,	
        Videos:0,	
        Total:1
    }, {
        Date:'2023-11-11',
        Likes:'Hidden',	
        Comments:0,	
        Posts:1,	
        Videos:0,	
        Total:1
    }
]
export default DailyStateTableData