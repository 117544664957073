import React from "react";
function BenifitsOfContentMarketing() {
  return (
    <>
      <div className="benifits">
        <div className="whyguestpostblock bg-gray">
          <div className="mainwapperwhyguestpost">
            <div className="WhyGuesttitleBlock">
              <h2>Benefits of Content Marketing</h2>
            </div>
            <div className="innertwoblocksec">
              <div className="block">
                <div className="icon-holder">
                  <img src="https://www.influglue.com/files/medialibrary/7df819bb3e5573d02c6891e1ed18e33b.png" />
                </div>
                <div className="content">
                  <h3>More visibility on search engine</h3>
                  <p>
                    With every new content, Google is going to index a new page.
                    Having more quality pages on your site means better chances
                    of getting ranked for more search queries. If you target
                    most searched long tail and short tail keywords you don’t
                    have to face any problem to appear in front of your
                    audience.
                  </p>
                </div>
              </div>
              <div className="block">
                <div className="icon-holder">
                  <img src="https://www.influglue.com/files/medialibrary/7348c65a000bdc640acff04c3b740d30.png" />
                </div>
                <div className="content">
                  <h3>More on-site content</h3>
                  <p>
                    More content marketing means better content on your site
                    which will provide your audience to stick around your
                    website more. They will get better opportunities to become
                    familiar with your brand which will lead to a better
                    conversion rate. With relevant and informative content your
                    viewers will stick to the site for more which is a good
                    thing.
                  </p>
                </div>
              </div>
              <div className="block">
                <div className="icon-holder">
                  <img src="https://www.influglue.com/files/medialibrary/516661bf70971d84c61c8cabc08df9ad.png" />
                </div>
                <div className="content">
                  <h3>Increased domain authority</h3>
                  <p>
                    Writing more quality and relevant content will increase both
                    relevance and trust of your site. If that content achieves
                    more inbound links from external sources the domain
                    authority of your website will increase even more. Higher
                    domain authority means a higher search engine ranking.
                  </p>
                </div>
              </div>
              <div className="block">
                <div className="icon-holder">
                  <img src="https://www.influglue.com/files/medialibrary/4f14eb24b300329564e85145d66b9f1d.png" />
                </div>
                <div className="content">
                  <h3>Increased referral traffic</h3>
                  <p>
                    For this, you need to fine-tune your content marketing
                    strategy a bit. You need to contribute to external links
                    through guest posts which will ensure more quality links
                    back to your site. Make sure the website you are posting
                    your link is authentic and have higher traffic, this it
                    could earn you thousands of new visitor to your site.
                  </p>
                </div>
              </div>
              <div className="block">
                <div className="icon-holder">
                  <img src="https://www.influglue.com/files/medialibrary/c1b4b0c6d86bb4f4dce17be0c50d77f3.png" />
                </div>
                <div className="content">
                  <h3>Improved brand reputation</h3>
                  <p>
                    Through content marketing on different sources, you can
                    achieve a better online reputation. When people read about
                    your brand, they will gradually build an impression of it.
                  </p>
                </div>
              </div>
              <div className="block">
                <div className="icon-holder">
                  <img src="https://www.influglue.com/files/medialibrary/c1b4b0c6d86bb4f4dce17be0c50d77f3.png" />
                </div>
                <div className="content">
                  <h3>Increased conversion</h3>
                  <p>
                    The main objective of content marketing should be to get
                    more valuable customers. Great content promotes customer
                    loyalty, make sure they must rely on a primary source of
                    information then you can make sure customer retention.
                  </p>
                </div>
              </div>
              <div className="block">
                <div className="icon-holder">
                  <img src="https://www.influglue.com/files/medialibrary/e1713a4e40bf7be6011185cc8c3f8940.png" />
                </div>
                <div className="content">
                  <h3>More social visibility</h3>
                  <p>
                    For increased exposure, you must leverage the power of your
                    content, share them on social media so that your viewers
                    will get to know more about your business. If the users like
                    your content they will like, share and comment which means
                    more audience will get to know about your brand which means
                    more social visibility. You never know how many of them can
                    be your potential customer.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default BenifitsOfContentMarketing;
