import { Rating } from "@mui/material"
import Footer from "../common/footer/Footer"
import Header from "../common/header/Header"
import '../styles/testimonialStyle.css'
import { TfiLocationPin } from "react-icons/tfi";


const Testimonials = () => {
    return (
        <>
            <Header />

            <div className="testimonial-box">
                <div className="div-box">
                    <h1>Testimonials</h1>
                </div>
                <div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/b090013f97fb9f58831d4a50f5521685.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>ASWATHI</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Bangalore</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={5} /></p>
                            <p>Thank you for the opportunity to collaborate. I look forward to future partnerships and continued success together.</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/ff3133f7b91780753f7aee023e002710.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>PRASHANT</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Noida</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={4} /></p>
                            <p>Experience is nice , polite people and support. I like the way Abaris is working</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/9fcaf928a774299d810cf28977ccf667.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>GURMEHAK</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Delhi</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={2} /></p>
                            <p>It was lovely working with the company especially Richa and I sincerely appreciate all the effort she has put into my practice and have enjoyed working with her. I hope to continue working with her for a long time. Also, I would highly recommend you, Richa, and your firm to anyone and am happy to speak about my experience working with you.</p> 
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/b18e935b7d4ece7fdf80811e1991e635.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>AJIT</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Bhagalpur</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={5} /></p>
                            <p>Abaris ke sath kaam karke bhot achha laga?❤️ aage bhi karte rahenge?❤️</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/d431646e5ca35147cd0902317f9a64e1.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>AMIT</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Delhi</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={2} /></p>
                            <p>Excellent Agency ...i am happy to work with you again....</p>
                        </div>
                    </div>
                     <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/e58d753e06693a6f990db62300c31529.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>PINKEY</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Bangalore</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={4} /></p>
                            <p>My go to Influencer marketing brand till now . Brings lots of valuable campaign and yes at good payout . Best so far in all directions for us as influencer marketing brand</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/5b8eb746f76eec87f0bc850a12ebf0af.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>ROOPA</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Pune</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={3} /></p>
                            <p>Had a wonderful experience work with Abaris. Looking forward for more successful collaborations together.</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/c4e55bf057614afe869cc69dcd996b3b.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>DEEPALI</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Delhi</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={3} /></p>
                            <p>I have been working for different projects with Abaris and it has always been very smooth . Content ideas , project clarity everything is perfect with supportive coordinators . Love working with them</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/f320d11426e9959502b7c34776d9a341.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>RIMJHIM</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Kolkata</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={3} /></p>
                            <p>I have done one campaign with Abaris and the experience was good</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/1b70d7cf9faa0d642d19e2ece22af55d.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>ADRIJA</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Kolkata</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={5} /></p>
                            <p>Very convenient! Richa was very polite to be working with</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/ec970d30336d588488d37fb1236c0be9.jpg" alt="img" />
                        </div>                         
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>MANOJ</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Chandigarh</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={5} /></p>
                            <p>Abaris is a really great platform for influencers to collaborate with brands. Abaris helped me get collaboration with Cropino Foods. It was a wonderful experience working with "Cropino Foods" and Abaris Team did smooth and admirable coordination throughout the whole collaboration process and helped me complete the collaboration deliverables within the given timeline. Special Thanks to Richa from Abaris Team, you did a commendable job. Thanks for all your cooperation throughout the whole campaign.</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/b090013f97fb9f58831d4a50f5521685.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>ASWATHI</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Bangalore</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={2} /></p>
                            <p>Thank you for the opportunity to collaborate. I look forward to future partnerships and continued success together.</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/ff3133f7b91780753f7aee023e002710.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>PRASHANT</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Noida</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={1} /></p>
                            <p>Experience is nice , polite people and support. I like the way Abaris is working</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/9fcaf928a774299d810cf28977ccf667.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>GURMEHAK</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Delhi</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={4} /></p>
                            <p>It was lovely working with the company especially Richa and I sincerely appreciate all the effort she has put into my practice and have enjoyed working with her. I hope to continue working with her for a long time. Also, I would highly recommend you, Richa, and your firm to anyone and am happy to speak about my experience working with you.</p> 
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/b18e935b7d4ece7fdf80811e1991e635.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>AJIT</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Bhagalpur</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={4} /></p>
                            <p>Abaris ke sath kaam karke bhot achha laga?❤️ aage bhi karte rahenge?❤️</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/d431646e5ca35147cd0902317f9a64e1.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>AMIT</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Delhi</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={5} /></p>
                            <p>Excellent Agency ...i am happy to work with you again....</p>
                        </div>
                    </div>
                     <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/e58d753e06693a6f990db62300c31529.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>PINKEY</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Bangalore</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={3} /></p>
                            <p>My go to Influencer marketing brand till now . Brings lots of valuable campaign and yes at good payout . Best so far in all directions for us as influencer marketing brand</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/5b8eb746f76eec87f0bc850a12ebf0af.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>ROOPA</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Pune</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={3} /></p>
                            <p>Had a wonderful experience work with Abaris. Looking forward for more successful collaborations together.</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/c4e55bf057614afe869cc69dcd996b3b.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>DEEPALI</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Delhi</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={4} /></p>
                            <p>I have been working for different projects with Abaris and it has always been very smooth . Content ideas , project clarity everything is perfect with supportive coordinators . Love working with them</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/f320d11426e9959502b7c34776d9a341.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>RIMJHIM</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Kolkata</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={2} /></p>
                            <p>I have done one campaign with Abaris and the experience was good</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/1b70d7cf9faa0d642d19e2ece22af55d.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>ADRIJA</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Kolkata</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={5} /></p>
                            <p>Very convenient! Richa was very polite to be working with</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/ec970d30336d588488d37fb1236c0be9.jpg" alt="img" />
                        </div>                         
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>MANOJ</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Chandigarh</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={5} /></p>
                            <p>Abaris is a really great platform for influencers to collaborate with brands. Abaris helped me get collaboration with Cropino Foods. It was a wonderful experience working with "Cropino Foods" and Abaris Team did smooth and admirable coordination throughout the whole collaboration process and helped me complete the collaboration deliverables within the given timeline. Special Thanks to Richa from Abaris Team, you did a commendable job. Thanks for all your cooperation throughout the whole campaign.</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/b090013f97fb9f58831d4a50f5521685.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>ASWATHI</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Bangalore</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={2} /></p>
                            <p>Thank you for the opportunity to collaborate. I look forward to future partnerships and continued success together.</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/ff3133f7b91780753f7aee023e002710.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>PRASHANT</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Noida</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={4} /></p>
                            <p>Experience is nice , polite people and support. I like the way Abaris is working</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/9fcaf928a774299d810cf28977ccf667.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>GURMEHAK</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Delhi</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={5} /></p>
                            <p>It was lovely working with the company especially Richa and I sincerely appreciate all the effort she has put into my practice and have enjoyed working with her. I hope to continue working with her for a long time. Also, I would highly recommend you, Richa, and your firm to anyone and am happy to speak about my experience working with you.</p> 
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/b18e935b7d4ece7fdf80811e1991e635.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>AJIT</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Bhagalpur</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={3} /></p>
                            <p>Abaris ke sath kaam karke bhot achha laga?❤️ aage bhi karte rahenge?❤️</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/d431646e5ca35147cd0902317f9a64e1.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>AMIT</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Delhi</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={3} /></p>
                            <p>Excellent Agency ...i am happy to work with you again....</p>
                        </div>
                    </div>
                     <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/e58d753e06693a6f990db62300c31529.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>PINKEY</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Bangalore</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={3} /></p>
                            <p>My go to Influencer marketing brand till now . Brings lots of valuable campaign and yes at good payout . Best so far in all directions for us as influencer marketing brand</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/5b8eb746f76eec87f0bc850a12ebf0af.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>ROOPA</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Pune</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={5} /></p>
                            <p>Had a wonderful experience work with Abaris. Looking forward for more successful collaborations together.</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/c4e55bf057614afe869cc69dcd996b3b.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>DEEPALI</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Delhi</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={5} /></p>
                            <p>I have been working for different projects with Abaris and it has always been very smooth . Content ideas , project clarity everything is perfect with supportive coordinators . Love working with them</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/f320d11426e9959502b7c34776d9a341.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>RIMJHIM</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Kolkata</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={2} /></p>
                            <p>I have done one campaign with Abaris and the experience was good</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/1b70d7cf9faa0d642d19e2ece22af55d.jpg" alt="img" />
                        </div>
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>ADRIJA</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Kolkata</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={3} /></p>
                            <p>Very convenient! Richa was very polite to be working with</p>
                        </div>
                    </div>
                    <div className="comnt-box">
                        <div>
                            <img src="https://www.influglue.com/files/profileimages/ec970d30336d588488d37fb1236c0be9.jpg" alt="img" />
                        </div>                         
                        <div className="detail-box">
                            <div className="person-info">
                                <h4>MANOJ</h4>
                                <p><TfiLocationPin className="mb-1 me-1" />Chandigarh</p>
                            </div>
                            <p><Rating name="size-medium" defaultValue={4} /></p>
                            <p>Abaris is a really great platform for influencers to collaborate with brands. Abaris helped me get collaboration with Cropino Foods. It was a wonderful experience working with "Cropino Foods" and Abaris Team did smooth and admirable coordination throughout the whole collaboration process and helped me complete the collaboration deliverables within the given timeline. Special Thanks to Richa from Abaris Team, you did a commendable job. Thanks for all your cooperation throughout the whole campaign.</p>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </>
    )
}

export default Testimonials
