import Footer from "../common/footer/Footer"
import Header from "../common/header/Header"
import FreqAskQuestion from "../components/FreqAskQues"
import HomeServiceBlock from "../components/homeServiceBlock/HomeServiceBlock"


const Services = () => {
  return (
    <>
      <Header/>
          <HomeServiceBlock/>
            <FreqAskQuestion/>
      <Footer/>
    </>
  )
}

export default Services
