import "./InfluencerSignup.css";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import img from "../../assest/images/influencer-boy.jpg";
import FreqAskQues from "../../components/FreqAskQues";
import Header from "../../common/header/Header";
import { useEffect, useState } from "react";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import Footer from "../../common/footer/Footer";

function InfluencerSignup() {
  const [industry, setImdustry] = useState([])
  const [category, setCategoryType] = useState([])
  const [finalCategoryTypeD, setFinalCategoryTypeD] = useState()
  const [finalIndustryTypeD, setFinalIndustryTypeD] = useState()
  const [brand, setBrand] = useState()
  const [finalbrandTypeD, setFinalbrandTypeD] = useState()
  const [readdivisionData, setReadDivisionData] = useState([])



  const [SignUpData, SetSignUpData] = useState({
      firstname: '',
      lastname: '',
      email: '',
      mobile: '',
      password: '',
      instagram:'',
      facebook:'',
      twitter:'',
      youtube:'',
      Gender:'',
      industry:'',
      category:'',
      brand:''

    })


  useEffect(() => {
    const getCategoryTypeData = async () => {
      const getCategoryName = []
      const reqCatData = await axios.get("https://onlineparttimejobs.in/api/category/public", {
        withCredentials: true
      });
      console.log(reqCatData.data)
      for (let i = 0; i < reqCatData.data.length; i++) {
        getCategoryName.push({ name: reqCatData.data[i]?.name, uid: reqCatData.data[i]?.uid })
      };
      if (getCategoryName.length) {
        setCategoryType(getCategoryName);
      }
    }
    getCategoryTypeData();
  }, []);


  useEffect(() => {
    const getIndustryData = async () => {
      const getIndustryName = []
      const reqData = await axios.get("https://onlineparttimejobs.in/api/industry/public", {
        withCredentials: true
      });
      console.log(reqData.data)
      for (let i = 0; i < reqData.data.length; i++) {
        getIndustryName.push({ name: reqData.data[i]?.name, uid: reqData.data[i]?.uid })
      };
      if (getIndustryName.length) {
        setImdustry(getIndustryName);
      }
    }
    getIndustryData();
  }, []);

  const handleChange=(e)=>{
    const inpName= e.target.name;
    const inpVar=e.target.value;
    const cloned={...SignUpData}
    cloned[inpName]=inpVar;
    SetSignUpData(cloned);
  }

const submitData= async ()=>{
  try {
        const add_Infulencer = await axios.post('https://onlineparttimejobs.in/api/sellerList/add_Sellers',{...SignUpData, category:finalCategoryTypeD,industry:finalIndustryTypeD,brand:finalbrandTypeD});
        SetSignUpData(add_Infulencer);
        console.log(add_Infulencer.data);
        console.log('Form data submitted successfully!');
      } catch (error) {
        console.log('Error submitting form:', error)
      }
    }

    useEffect(() => {
      const getBrandData = async () => {
        const getBrandName = []
        const reqBrandData = await axios.get("https://onlineparttimejobs.in/api/brand/public", {
          withCredentials: true
        });
        console.log(reqBrandData.data)
        for (let i = 0; i < reqBrandData.data.length; i++) {
          getBrandName.push({ name: reqBrandData.data[i]?.name, uid: reqBrandData.data[i]?.uid })
        };
        if (getBrandName.length) {
          setBrand(getBrandName);
        }
      }
      getBrandData();
    }, []);

    const GetDivsionData = async () => {
      try {
          const redDivisionData = await axios.get('https://onlineparttimejobs.in/api/division/public',{withCredentials:true})
          console.log('bbb',redDivisionData.data);
          setReadDivisionData(redDivisionData.data);
          // console.log(redDivisionData.data);
      } catch (error) {
          console.log(error)
      }
  }
  useEffect(() => {
      GetDivsionData();
  }, []);
 
  return (
    <>
      <Header />
      <section className="mainSignup_holder">
        <div className="container mainWrapper">
          <div className="signupForm">
            <h2>Sign Up With Us</h2>
            <div className="innerForm">
              <form action="formBasicDetails">
                <div className="col-md-12 ">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="First Name*"
                    className="mb-3"
                  >
                    <Form.Control
                     name='firstname'
                     onChange={handleChange}
                     type="text" placeholder="first name" />
                  </FloatingLabel>
                </div>
                <div className="col-md-12 ">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Last Name*"
                    className="mb-3"
                  >
                    <Form.Control
                     name='lastname'
                     onChange={handleChange}
                     type="text" placeholder="Last name" />
                  </FloatingLabel>
                </div>
                <div className="col-md-12 ">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Email*"
                    className="mb-3"
                  >
                    <Form.Control
                     name='email'
                     onChange={handleChange}
                     type="text" placeholder="Email" />
                  </FloatingLabel>
                </div>
                <div className="col-md-12 ">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="password"
                    className="mb-3"
                  >
                    <Form.Control
                     name='password'
                     onChange={handleChange}
                     type="text" placeholder="Enter Password" />
                  </FloatingLabel>
                </div>
                <div className="col-md-12 ">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Enter 10 digit mobile number*"
                    className="mb-3"
                  >
                    <Form.Control
                    name='mobile'
                    onChange={handleChange}
                     type="text" placeholder="phone no" />
                    <small style={{ display: "flex" }}>
                      <strong>Note:</strong> Mobile number should not include
                      +91
                    </small>
                  </FloatingLabel>
                </div>
                <div className="col-md-12 ">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Instagram ID"
                    className="mb-3"
                  >
                    <Form.Control
                    name='instagram'
                    onChange={handleChange}
                     type="text" placeholder="Instagram ID" />
                  </FloatingLabel>
                </div>
                <div className="col-md-12 ">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Facebook ID"
                    className="mb-3"
                  >
                    <Form.Control
                     name='facebook'
                     onChange={handleChange}
                     type="text" placeholder="Facebook ID" />
                  </FloatingLabel>
                </div>
                <div className="col-md-12 ">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Twitter ID"
                    className="mb-3"
                  >
                    <Form.Control
                      name='twitter'
                      onChange={handleChange}
                     type="text" placeholder="Twitter ID" />
                  </FloatingLabel>
                </div>
                <div className="col-md-12 ">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Youtube ID"
                    className="mb-3"
                  >
                    <Form.Control
                      name='youtube'
                      onChange={handleChange}
                     type="text" placeholder="Youtube ID" />
                  </FloatingLabel>
                </div>
                <div className="col-md-12 selectGender">
                  <ul>
                    <li>I am*</li>
                    <li>
                      <Form.Check
                      onChange={handleChange}
                       type="radio" label="Male" name="Gender" value='male' />
                    </li>
                    <li>
                      <Form.Check
                      onChange={handleChange}
                       type="radio" label="female" name="Gender" value='female' />
                    </li>
                  </ul>
                </div>
                <div className="col-md-12" style={{ marginBottom: "13px", textAlign: 'start' }}>
                  <label className="m-2" htmlFor="flexRadioDefault1">Industry Type <span style={{ color: 'red' }}>*</span></label>
                  <Multiselect
                    name='industry'
                    onChange={handleChange}
                    isObject={true}
                    displayValue="name"
                    options={industry}
                    showCheckbox
                    selectedValues={[]}
                    onRemove={(selectedCat) => {
                      const selectedIds = selectedCat.map((cat) => {
                        return cat.uid
                      })
                      setFinalIndustryTypeD(selectedIds)
                    }}
                    onSelect={(selectedCat) => {
                      const selectedIds = selectedCat.map((cat) => {
                        return cat.uid
                      })
                      setFinalIndustryTypeD(selectedIds)
                    }}
                  />
                </div>
                <div className="col-md-12" style={{ marginBottom: "13px", textAlign: 'start' }}>
                  <label className="m-2" htmlFor="flexRadioDefault1">Category Type <span style={{ color: 'red' }}>*</span></label>
                  <Multiselect
                  name='category'
                  onChange={handleChange}
                    className="multiSelect"
                    isObject={true}
                    displayValue="name"
                    options={category}
                    showCheckbox
                    selectedValues={[]}
                    onRemove={(selectedCat) => {
                      const selectedIds = selectedCat.map((cat) => {
                        return cat.uid
                      })
                      setFinalCategoryTypeD(selectedIds)
                    }}
                    onSelect={(selectedCat) => {
                      const selectedIds = selectedCat.map((cat) => {
                        return cat.uid
                      })
                      setFinalCategoryTypeD(selectedIds)
                    }}
                  />
                </div>
                <div className="col-md-12 " style={{ marginBottom: "13px" }}>
                  <Form.Select size="lg" aria-label="Default select example">
                    <option>Select Zone*</option>
                     {readdivisionData?.map((item)=>{
                     return <option key={item}>{item.title}</option>
                     })}
                  </Form.Select>
                </div>
                <div className="col-md-12 " style={{ marginBottom: "13px",textAlign:'start' }}>
                <label className="m-2" htmlFor="flexRadioDefault1">select Brand <span style={{ color: 'red' }}>*</span></label>
                  <Multiselect
                    name='brand'
                    onChange={handleChange}
                    isObject={true}
                    displayValue="name"
                    options={brand}
                    showCheckbox
                    selectedValues={[]}
                    onRemove={(selectedCat) => {
                      const selectedIds = selectedCat.map((cat) => {
                        return cat.uid
                      })
                      setFinalbrandTypeD(selectedIds)
                    }}
                    onSelect={(selectedCat) => {
                      const selectedIds = selectedCat.map((cat) => {
                        return cat.uid
                      })
                      setFinalbrandTypeD(selectedIds)
                    }}
                  />
                </div>
                <div className="col-md-12 " style={{ marginBottom: "13px" }}>
                <FloatingLabel
                    controlId="floatingInput"
                    label="select"
                    className="mb-3"
                  >
                    <Form.Control
                     name='DOB'
                     onChange={handleChange}
                     type="date" placeholder="Select DOB" />
                  </FloatingLabel>
                </div>
                <div className="col-md-12 " id="proceedBtn">
                  <Button type="button" onClick={submitData} variant="primary" size="lg">
                    PROCEED
                  </Button>
                </div>
              </form>
            </div>
          </div>
          <div className="modelImg">
            <img src={img} alt="" className="fluid-img" />
          </div>

        </div>
        <div className="questionFaq">
          <FreqAskQues />
        </div>
      </section>
      <Footer/>
    </>
  );
}
export default InfluencerSignup;
