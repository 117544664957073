import { Box, Button, TextField } from "@mui/material"
import Footer from "../common/footer/Footer"
import Header from "../common/header/Header"
import '../styles/advertiserSignupStyle.css'
import axios from "axios"
import { useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"


const AdvertiserSignUpMoreDetails = () => {
    const navigate = useNavigate()
    const params = useParams()
    console.log(params)
    
    const [brandUpdateData, SetBrandUpdateData] = useState({
        businessname: '',
        businesswebsite: '',
        gstnumber: '',
        businessaddress: '',
    })
    const inputHandleUpdateChange = (e) => {
        const inputName = e.target.name;
        const inputVar = e.target.value;
        const cloned = { ...brandUpdateData }
        cloned[inputName] = inputVar;
        SetBrandUpdateData(cloned);
    }

    const submitBrandMoreDetail = async () => {
        try {
            console.log(brandUpdateData)
            const add_BrandDetail = await axios.put('https://onlineparttimejobs.in/api/user/updateBus', { ...brandUpdateData },
                {
                    headers: {
                        // Authorization:'Bearer '+ params.token
                        Authorization: `Bearer ${params.token}`,
                    }
                });

            console.log(add_BrandDetail.data);
            //   SetBrandUpdateData(add_BrandDetail);
            navigate('/sign-in')
            console.log('Form data submitted successfully!');
        } catch (error) {
            console.log('Error submitting form:', error)
        }
    }

    return (
        <>
            <Header />
            <Box className='advertise-box'>
                <div>
                    <div className="sign-up-text">
                        <h1 className=" fw-bold">A few more details</h1>
                    </div>
                    <div className="input-area-div">
                        <div>
                            <TextField
                                name="businessname"
                                onChange={inputHandleUpdateChange}
                                placeholder="Business Name" fullWidth />
                        </div>
                        <div>
                            <TextField
                                name="businesswebsite"
                                onChange={inputHandleUpdateChange}
                                placeholder="Business Website (Optional)" fullWidth />
                        </div>
                        <div>
                            <TextField
                                name="gstnumber"
                                onChange={inputHandleUpdateChange}
                                placeholder="GST Number (Optional)" fullWidth />
                        </div>
                        <div>
                            <TextField
                                name="businessaddress"
                                onChange={inputHandleUpdateChange}
                                placeholder="Business Address"
                                fullWidth
                            />
                        </div>

                        <div className="advertise-btn-div">
                            <Link>
                                <Button type="button" onClick={submitBrandMoreDetail}>proceed</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </Box>
            <Footer />
        </>
    )
}

export default AdvertiserSignUpMoreDetails

