import React from "react";
import './WhyGuestPost.css';
function WhyGuestPost() {
  return (
    <>
      <div className="whyguestpostblock bg-gray">
        <div className="mainwapperwhyguestpost">
          <div className="WhyGuesttitleBlock">
            <h2>Why Abaris Guest-Posts?</h2>
            <p>
              We don’t sell backlinks, we take care of your outreach campaigns
              to secure contextual and image backlinks from high-authority, real
              influencer blogs with REAL traffic
            </p>
          </div>
          <div className="innertwoblocksec">
            <div className="block">
              <div className="icon-holder">
                <img alt="img" src="https://www.influglue.com/files/medialibrary/03c94c8bff8cf4ff1a5fb800e683870b.png" />
              </div>
              <div className="content">
                <h3>You Approve the Domains</h3>
                <p>
                  You understand your business and the buyer persona the best,
                  with us you have complete flexibility to choose the domains
                  that are well suited for your business. If you require any
                  help you will always find our experts team members behind you.
                </p>
              </div>
            </div>
            <div className="block">
              <div className="icon-holder">
                <img alt="img" src="https://www.influglue.com/files/medialibrary/70664448cef217ab57d547100b34e46d.png" />
              </div>
              <div className="content">
                <h3>1K - 100K+ Publisher’s Traffic</h3>
                <p>
                  Our each guest post opportunity will ensure high-quality SEO
                  metrics that are backed by real-time data. We will ensure the
                  exact quality traffic you are looking to improve the
                  visibility of your site and funnel new leads by making sure
                  you are discoverable.
                </p>
              </div>
            </div>
            <div className="block">
              <div className="icon-holder">
                <img alt="img" src="https://www.influglue.com/files/medialibrary/2b2b95addf2feadd6e6dc3ec2c023f73.png" />
              </div>
              <div className="content">
                <h3>Relevant Sites, In-Content Links</h3>
                <p>
                  We will only allow traffic from websites that are relevant to
                  your project, our in-content link will ensure your content
                  will reach your target audience and get shared by them. If you
                  need help connecting with a company we will send you more
                  recommendations.
                </p>
              </div>
            </div>
            <div className="block">
              <div className="icon-holder">
                <img alt="img" src="https://www.influglue.com/files/medialibrary/0ce91c5021d939ee2aee58ebf724b624.png" />
              </div>
              <div className="content">
                <h3>1000+ Words Quality Content</h3>
                <p>
                  You ou would get a minimum of 1000+ words content specially
                  curated to the requirement of your backlinks. Our team of
                  writers and editors will ensure SEO compliance and 100% niche
                  relevancy that appeal to your audience emotionally and act as
                  a lead magnet.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default WhyGuestPost;
