import "./Contact.css";
import "bootstrap/dist/css/bootstrap.css";
import img from "../../assest/images/contactbg.jpg";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import { CiMail } from "react-icons/ci";
import { FaLocationDot } from "react-icons/fa6";
import Header from "../../common/header/Header";
import Footer from "../../common/footer/Footer";

function Contact() {
  return (
    <>
    <Header/>
      <div className="container" style={{ padding: "10px" }}>
        <div className="row">
          <div className="col-md-12">
            <div className="breadcrum">
              <div className="main wrapper">
                <a href="/">Home</a>
                <span>{">>"}</span>
                Contact
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="banner-section">
        <div>
          <img src={img} className="img-fluid" alt=" " />
        </div>

        <div className="container headerContainer">
          <div className="row">
            <div className="col-md-12" id="headerCol">
              <div className="header-heading">
                <h2>CONTACT US</h2>
              </div>
              <div className="header-para">
                <p>
                  Be a part of the Abaris family- the most famous influencer
                  marking <br /> platform of India. Get in touch with us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="form-section" style={{ backgroundColor: "#f1f1f1" }}>
        <div className="form">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 formHeading" style={{ padding: "0px" }}>
                {" "}
                <h2>Fill this form and get in touch quickly!</h2>
              </div>
            </div>
            <div className="row inner-formHeading">
              <div className="col-md-12 inputsForms">
                <Form.Select size="lg" aria-label="Default select example">
                  <option>Choose topic</option>
                  <option value="1">Payment Withdrawal or Transfer</option>
                  <option value="2">Sign up and Login issue </option>
                  <option value="3">Other</option>
                </Form.Select>
              </div>
              <div className="col-md-12 inputsForms">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Name*"
                  className="mb-3"
                >
                  <Form.Control type="text" placeholder="name@example.com" />
                </FloatingLabel>
              </div>
              <div className="col-md-12 inputsForms">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Email*"
                  className="mb-3"
                >
                  <Form.Control type="email" placeholder="name@example.com" />
                </FloatingLabel>
              </div>
              <div className="col-md-12 inputsForms">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Mobile*"
                  className="mb-3"
                >
                  <Form.Control type="number" placeholder="name@example.com" />
                </FloatingLabel>
              </div>
              <div className="col-md-12 inputsForms">
                <Form.Select size="lg" aria-label="Default select example">
                  <option>Choose city</option>
                  <option value="1">Delhi</option>
                  <option value="2">Mumbai</option>
                  <option value="3">Agra</option>
                </Form.Select>
              </div>
              <div className="col-md-12 inputsForms">
                <FloatingLabel controlId="floatingTextarea2" label="Message">
                  <Form.Control
                    as="textarea"
                    placeholder="Leave a message here"
                    style={{ height: "150px" }}
                  />
                </FloatingLabel>
              </div>
              <div className="col-md-12 inputsForms" id="Submit_btn">
                <Button variant="primary" size="lg">
                  SUBMIT
                </Button>
              </div>
            </div>
          </div>
        </div>

        
       
      </section>

      <section className="bottom-section" style={{ backgroundColor: "#f1f1f1" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 contactInfoCol">
              <div className="contact-info-block">
                <CiMail style={{ fontSize: "30px" }} />
                <span>
                  <a href="/" style={{ fontSize: "20px", fontWeight: "700" }}>
                    contact@gmail.com
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row ">
            <div className="col-md-12 contactInfoPara">
              <p>Softz Solutions & Co Pvt Ltd.</p>
            </div>
          </div>
          <div className="row">
          <div className="col-md-12 contactInfoAdd">
         <span>
         <FaLocationDot />

          </span>

            <p>
              796, Purbachal Main Road, Second St, Gitanjali Park, Kolkata -
              700078, West Bengal, India
            </p>
          </div>
        </div>
        </div>
        </section>
        <Footer/>
    </>
  );
}
export default Contact;
