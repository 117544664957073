import React from "react";
import "./HomeCtg.css";
function HomeCtg() {
  return (
    <>
      <div className="homectgry">
        <div className="homectgMainwapper">
          <h2>Business Segments & Categories</h2>
          <p>
            Abaris Influencer Marketing helps customers in following business
            categories where Influencer Marketing Services have proven to be
            effective if executed with proper campaign strategies
          </p>
          <div className="inner-ctg">
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/fa07fa0365cbd65e0ed79d3a0e39023e.png"
                  alt="innctg"
                  style={{ width: 65, height: 65 }}
                />
                <h3>Auto</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/47f2e3e3485beff4041e9277082ae026.png"
                  alt="innctg"
                  style={{ width: 65, height: 65 }}
                />
                <h3>Beauty</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/b37f4ed496a745468bbe542448fed3a1.png"
                  alt="innctg"
                  style={{ width: 65, height: 65 }}
                />
                <h3>Books</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/676d609f016db5df66a9f0071b7adb4c.png"
                  alt="innctg"
                  style={{ width: 65, height: 65 }}
                />
                <h3>COUPLE</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/68fc27a0d21c40b8761e27997975fe59.png"
                  alt="innctg"
                  style={{ width: 65, height: 65 }}
                />
                <h3>EDUCATION</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/12e1a82d983dbbf380934f4ab2ec1774.png"
                  alt="innctg"
                  style={{ width: 65, height: 65 }}
                />
                <h3>ENTERTAINMENTS</h3>
              </a>
            </div>

            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/92c91f7fc1951234b2d7cb754c3b5b96.png"
                  alt="innctg"
                  style={{ width: 65, height: 65 }}
                />
                <h3>FITNESS</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/d6e3b292d9a953761987b5d353c53709.png"
                  alt="innctg"
                  style={{ width: 65, height: 65 }}
                />
                <h3>FOOD</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/d403271eb4f8c6ca89dca559ae48e5a1.png"
                  alt="innctg"
                  style={{ width: 65, height: 65 }}
                />
                <h3>GAMING</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/c493682b826e496afaca6eb656b2fccc.png"
                  alt="innctg"
                  style={{ width: 65, height: 65 }}
                />
                <h3>HEALTH</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/1ba18321f17efeddeab967d0fb63807e.png"
                  alt="innctg"
                  style={{ width: 65, height: 65 }}
                />
                <h3>HOME</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/8b15397368a5ad7d9e07d1f062d0b1da.png"
                  alt="innctg"
                  style={{ width: 65, height: 65 }}
                />
                <h3>INVESTMENT</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/8f8b6e4256475946870bbea0b71135c8.png"
                  alt="innctg"
                  style={{ width: 65, height: 65 }}
                />
                <h3>LIFESTYLE</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/bc477cd580db8ec01a001d2e5cfc804a.png"
                  alt="innctg"
                  style={{ width: 65, height: 65 }}
                />
                <h3>PARENTING</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/934707597788fa9b980b22d024ec21df.png"
                  alt="innctg"
                  style={{ width: 65, height: 65 }}
                />
                <h3>MONEY</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/7a000b127a5bcbead541e3b03a0a0beb.png"
                  alt="innctg"
                  style={{ width: 65, height: 65 }}
                />
                <h3>PHOTOGRAPHY</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/db098934a4c4289aad7bbdbd526d935d.png"
                  alt="innctg"
                  style={{ width: 65, height: 65 }}
                />
                <h3>RESTAURANTS</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/60c847864b895f313881ecf459026a71.png"
                  alt="innctg"
                  style={{ width: 65, height: 65 }}
                />
                <h3>TECHNOLOGY</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/c7ffab862683ba4a506f083168e0afba.png"
                  alt="innctg"
                  style={{ width: 65, height: 65 }}
                />
                <h3>TRAVEL</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/844eec72788a6481016babd403b3389f.png"
                  alt="innctg"
                  style={{ width: 65, height: 65 }}
                />
                <h3>WOMEN'S FASHION</h3>
              </a>
            </div>
            <div className="block">
              <a href="/">
                <img
                  className=""
                  src="https://www.influglue.com/files/medialibrary/54772d38c57edbeff28ef9d1690feeef.png"
                  alt="innctg"
                  style={{ width: 65, height: 65 }}
                />
                <h3>PET</h3>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default HomeCtg;
