import { Box, Button, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { Favorite, FavoriteBorder } from "@mui/icons-material";

import Footer from "../common/footer/Footer"
import Header from "../common/header/Header"
import '../styles/infulencerDetailStyle.css'
import { Tab, TabPanel, Tabs, TabsList } from "@mui/base"
import DailyStateTableData from "../data/DailyStateTableData"
import { GoComment } from "react-icons/go";
import { IoMdHeart } from "react-icons/io";
import { Link } from "react-router-dom";
import OurinfluencerData from "../data/OurInfluencerData";
// import InfluencerDetailData from "../data/InfluencerDetailData";
import RecentPostData from "../data/RecentPostData";

const InfluencerDetail = () => {
    return (
        <>
            <Header />

            <div className="topdiv-infulencer-detail">
            </div>

            <div className="infulencer-detail-main">
                <div className=" col-sm-4 rightmain-div">
                    <div className="rightimg-div">
                        <img src="https://www.influglue.com/files/profileimages/9d297f2de472f062ffc0c65bbe5e0c0a.jpg" alt="img" />
                        <div className="btn">
                            <Button>Message Bushra</Button>
                        </div>
                    </div>
                </div>

                <div className=" col-sm-8">
                    <div className='influencer-bio'>
                        <h1>Bushra</h1>
                        <p>Delhi</p>
                        <p>Instagram Followers: <b>35608</b></p>
                        <p>Interests: <b>Health, Lifestyle, Parenting, Education, Food, Mom</b></p>
                    </div>

                    <div className="mt-4">
                        <Tabs defaultValue={0}>
                            <TabsList>
                                <div className="detail-div">
                                    <Tab className="detail-tab" value={0}> About me</Tab>
                                    <Tab className="detail-tab" value={1}>Social Stats</Tab>
                                    <Tab className="detail-tab" value={2}>Work Samples</Tab>
                                </div>
                            </TabsList>
                            <TabPanel value={0}>
                                <div className="detailtab-div">
                                    <h3>About me</h3>
                                    <p>Doctor by profession. Health, Parenting social education Lifestyle Blogger and social media Influencer.</p>
                                    <p>I've good reach across all social media platforms and blog</p>
                                </div>
                            </TabPanel>
                            <TabPanel value={1}>
                                <div className="detailtab-div">
                                    <h3 className="">Other Social Statistics</h3>
                                    <div className=" d-flex flex-wrap">
                                        <div className="socialmedia-div">
                                            <img src="https://www.influglue.com/public/frontend/images/blog.jpg?v=1688734414" alt="img" />
                                            <p>DA Value: 28</p>
                                        </div>
                                        <div className="socialmedia-div">
                                            <img src="https://www.influglue.com/public/frontend/images/facebook.jpg?v=1688734414" alt="img" />
                                            <p>No of Followers: 9461</p>
                                        </div>
                                        <div className="socialmedia-div">
                                            <img src="https://www.influglue.com/public/frontend/images/twitter.jpg?v=1688734414" alt="img" />
                                            <p>No of Followers: 11800</p>
                                        </div>

                                        <div className="socialmedia-div">
                                            <img src="https://www.influglue.com/public/frontend/images/pinterest.jpg?v=1688734414" alt="img" />
                                            <p>No of Followers: 640</p>
                                        </div>
                                        <div className="socialmedia-div">
                                            <img src="https://www.influglue.com/public/frontend/images/youtube.jpg?v=1688734414" alt="img" />
                                            <p>No of Subscribers: 527</p>
                                        </div>
                                        <div className="socialmedia-div">
                                            <img src="https://www.influglue.com/public/frontend/images/tiktok.jpg?v=1688734414" alt="img" />
                                            <p>Followers: 143</p>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={2}>
                                <div className="detailtab-div">
                                    <h3>My Work Samples</h3>
                                    <div className="worksample">
                                        <h6>Facebook Live Videos</h6>
                                        <p>Social awareness</p>
                                        <div className=" d-flex">
                                            <p>https://www.facebook.com/1xxxxxxxxx</p>
                                            <Button size="small" variant="outlined">UNLOCK</Button>
                                        </div>
                                    </div>

                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>

                    <div className="instagram-statistics-div">
                        <div>
                            <h2>Bushra's Instagram statistics</h2>
                            <div className="instagram-div">
                                <ul>
                                    <li>
                                        <span>FOLLOWERS</span>
                                        <p>35.6K</p>
                                    </li>
                                    <li>
                                        <span>FOLLOWING</span>
                                        <p> 5.3K</p>
                                    </li>
                                    <li>
                                        <span>POST</span>
                                        <p>1.3K</p>
                                    </li>

                                    <li>
                                        <span>AVG. LIKES</span>
                                        <p>353</p>
                                    </li>

                                </ul>
                            </div>
                        </div>

                        <div className="instagram-div">
                            <ul>
                                <li>
                                    <span>AVG. COMMENTS</span>
                                    <p>14</p>
                                </li>
                                <li>
                                    <span>ENG. RATE</span>
                                    <p> 1.03%</p>
                                </li>
                            </ul>
                        </div>

                        <div className="Statistical-diff">
                            <h6>Statistical Difference (Last 2 Data Fetch)</h6>
                            <div className="instagram-Statistical-diff-div">
                                <ul>
                                    <li>
                                        <span>POST</span>
                                        <p>1</p>
                                    </li>
                                    <li>
                                        <span>AVG. LIKES</span>
                                        <p> -1</p>
                                    </li>
                                    <li>
                                        <span>AVG. COMMENTS</span>
                                        <p>0</p>
                                    </li>

                                    <li>
                                        <span>FOLLOWERS HIKE</span>
                                        <p>-80</p>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <Box className='hastagbtn-div mt-3'>
                        <h5 className="m-3">Profile Hashtags (Random 20)</h5>
                        <div className="all-btn-div">
                            <Button size="small" className="btn" variant="outlined">#LorealParisIndia</Button>
                            <Button size="small" className="btn" variant="outlined">#healthyhair</Button>
                            <Button size="small" className="btn" variant="outlined">#GLowWithGlycolic</Button>
                            <Button size="small" className="btn" variant="outlined">#haircareroutine</Button>
                            <Button size="small" className="btn" variant="outlined">#naturalhaircare</Button>
                            <Button size="small" className="btn" variant="outlined">#carehair</Button>
                            <Button size="small" className="btn" variant="outlined">#herbal</Button>
                            <Button size="small" className="btn" variant="outlined">#GlycolicBrightSerum</Button>
                            <Button size="small" className="btn" variant="outlined">#herbalifestyle</Button>
                            <Button size="small" className="btn" variant="outlined">#DontGetCaughtByHPV</Button>
                            <Button size="small" className="btn" variant="outlined">#LoveBeautyAndPlanet</Button>
                            <Button size="small" className="btn" variant="outlined">#AD</Button>
                            <Button size="small" className="btn" variant="outlined">#stronghair</Button>
                            <Button size="small" className="btn" variant="outlined">#herbaloil</Button>
                            <Button size="small" className="btn" variant="outlined">#CurryLeaves</Button>
                            <Button size="small" className="btn" variant="outlined">#haircare</Button>
                            <Button size="small" className="btn" variant="outlined">#shinyhair</Button>
                            <Button size="small" className="btn" variant="outlined">#healthyhabits</Button>
                            <Button size="small" className="btn" variant="outlined">#haircaretips</Button>
                            <Button size="small" className="btn" variant="outlined">#GlycolicAcid</Button>
                        </div>
                    </Box>

                    <Box className='hastagbtn-div mt-3'>
                        <h5 className="m-3">Profile Mentions (Random 20)</h5>
                        <div className="all-btn-div">
                            <Button size="small" className="btn" variant="outlined">@lorealparis</Button>
                            <Button size="small" className="btn" variant="outlined">@indulekha_care</Button>
                            <Button size="small" className="btn" variant="outlined">@CaratLane</Button>
                            <Button size="small" className="btn" variant="outlined">@lovebeautyandplanet_in</Button>
                            <Button size="small" className="btn" variant="outlined">@myntra</Button>
                            <Button size="small" className="btn" variant="outlined">@treatmentzaroorihai</Button>
                            <Button size="small" className="btn" variant="outlined">@meril_addmoretolife</Button>
                            <Button size="small" className="btn" variant="outlined">@kritikabhasin25</Button>
                            <Button size="small" className="btn" variant="outlined">@caratlane</Button>
                            <Button size="small" className="btn" variant="outlined">@maybelline_ind</Button>
                            <Button size="small" className="btn" variant="outlined">@sunfeastfarmlite</Button>
                            <Button size="small" className="btn" variant="outlined">@_jeasszi</Button>
                            <Button size="small" className="btn" variant="outlined">@lovemyraga</Button>
                            <Button size="small" className="btn" variant="outlined">@titanwatchesindia</Button>
                            <Button size="small" className="btn" variant="outlined">@pintola</Button>
                            <Button size="small" className="btn" variant="outlined">@nykaa</Button>
                            <Button size="small" className="btn" variant="outlined">@emamihealthyandtasty</Button>
                            <Button size="small" className="btn" variant="outlined">@proteinchefindia</Button>
                            <Button size="small" className="btn" variant="outlined">@saachi</Button>
                            <Button size="small" className="btn" variant="outlined">@aqualogica</Button>
                        </div>
                    </Box>

                    <div className="tablediv">
                        <h5 className="m-1">Daily Stats</h5>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow className="headertable">
                                        <TableCell>Date</TableCell>
                                        <TableCell align="center">Likes</TableCell>
                                        <TableCell align="center">Comments</TableCell>
                                        <TableCell align="center">Posts</TableCell>
                                        <TableCell align="center">Videos</TableCell>
                                        <TableCell align="center">Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {DailyStateTableData.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell component="th" scope="row">{row.Date} </TableCell>
                                            <TableCell align="center">{row.Likes} </TableCell>
                                            <TableCell align="center">{row.Comments}</TableCell>
                                            <TableCell align="center">{row.Posts}</TableCell>
                                            <TableCell align="center">{row.Videos}</TableCell>
                                            <TableCell align="center">{row.Total}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <div className="recent-post-div">
                        <h5 className="m-1">Recent Posts</h5>

                        <div className="abc d-flex flex-wrap">
                            <div className="post-box">
                                <div>
                                    <img src="https://www.influglue.com/files/smacrawl/instagram/3710/3233705327428613130.jpg" alt="img" />
                                </div>
                                <div className="post-like-cmnt">
                                    <p><IoMdHeart className="icon text-danger" />Hidden</p>
                                    <p><GoComment className="icon" />0</p>
                                </div>
                            </div>
                            <div className="post-box">
                                <div>
                                    <img src="https://www.influglue.com/files/smacrawl/instagram/3710/3211063969888961585.jpg" alt="img" />
                                </div>
                                <div className="post-like-cmnt">
                                    <p><IoMdHeart className="icon text-danger" />78</p>
                                    <p><GoComment className="icon" />68</p>
                                </div>
                            </div>

                            <div className="post-box">
                                <div>
                                    <img src="https://www.influglue.com/files/smacrawl/instagram/3710/3172887840910607049.jpg" alt="img" />
                                </div>
                                <div className="post-like-cmnt">
                                    <p><IoMdHeart className="icon text-danger" />467</p>
                                    <p><GoComment className="icon" />54</p>
                                </div>
                            </div>
                            <div className="post-box">
                                <div>
                                    <img src="https://www.influglue.com/files/smacrawl/instagram/3710/3164169664401128512.jpg" alt="img" />
                                </div>
                                <div className="post-like-cmnt">
                                    <p><IoMdHeart className="icon text-danger" />Hidden</p>
                                    <p><GoComment className="icon" />21</p>
                                </div>
                            </div>
                            <div className="post-box">
                                <div>
                                    <img src="https://www.influglue.com/files/smacrawl/instagram/3710/3139324109272396920.jpg" alt="img" />
                                </div>
                                <div className="post-like-cmnt">
                                    <p><IoMdHeart className="icon text-danger" />188</p>
                                    <p><GoComment className="icon" />54</p>
                                </div>
                            </div>
                            <div className="post-box">
                                <div>
                                    <img src="https://www.influglue.com/files/smacrawl/instagram/3710/3137228199636832547.jpg" alt="img" />
                                </div>
                                <div className="post-like-cmnt">
                                    <p><IoMdHeart className="icon text-danger" />30</p>
                                    <p><GoComment className="icon" />23</p>
                                </div>
                            </div>
                            <div className="post-box">
                                <div>
                                    <img src="https://www.influglue.com/files/smacrawl/instagram/3710/3135212896862631178.jpg" alt="img" />
                                </div>
                                <div className="post-like-cmnt">
                                    <p><IoMdHeart className="icon text-danger" />1.8K</p>
                                    <p><GoComment className="icon" />67</p>
                                </div>
                            </div>
                            <div className="post-box">
                                <div>
                                    <img src="https://www.influglue.com/files/smacrawl/instagram/3710/3131465565981677017.jpg" alt="img" />
                                </div>
                                <div className="post-like-cmnt">
                                    <p><IoMdHeart className="icon text-danger" />1.8K</p>
                                    <p><GoComment className="icon" />67</p>
                                </div>
                            </div>
                            <div className="post-box">
                                <div>
                                    <img src="https://www.influglue.com/files/smacrawl/instagram/3710/3114179020362461083.jpg" alt="img" />
                                </div>
                                <div className="post-like-cmnt">
                                    <p><IoMdHeart className="icon text-danger" />1.8K</p>
                                    <p><GoComment className="icon" />67</p>
                                </div>
                            </div>
                            <div className="post-box">
                                <div>
                                    <img src="https://www.influglue.com/files/smacrawl/instagram/3710/3083696208816142805.jpg" alt="img" />
                                </div>
                                <div className="post-like-cmnt">
                                    <p><IoMdHeart className="icon text-danger" />1.8K</p>
                                    <p><GoComment className="icon" />67</p>
                                </div>
                            </div>

                            <div className="post-box">
                                <div>
                                    <img src="https://www.influglue.com/files/smacrawl/instagram/3710/3083053889226581342.jpg" alt="img" />
                                </div>
                                <div className="post-like-cmnt">
                                    <p><IoMdHeart className="icon text-danger" />1.8K</p>
                                    <p><GoComment className="icon" />67</p>
                                </div>
                            </div>
                            <div className="post-box">
                                <div>
                                    <img src="https://www.influglue.com/files/smacrawl/instagram/3710/3080615526862428443.jpg" alt="img" />
                                </div>
                                <div className="post-like-cmnt">
                                    <p><IoMdHeart className="icon text-danger" />1.8K</p>
                                    <p><GoComment className="icon" />67</p>
                                </div>
                            </div>
                            <div className="post-box">
                                <div>
                                    <img src="https://www.influglue.com/files/smacrawl/instagram/3710/3075012635590075852.jpg" alt="img" />
                                </div>
                                <div className="post-like-cmnt">
                                    <p><IoMdHeart className="icon text-danger" />1.8K</p>
                                    <p><GoComment className="icon" />67</p>
                                </div>
                            </div>
                            <div className="post-box">
                                <div>
                                    <img src="https://www.influglue.com/files/smacrawl/instagram/3710/3052355957690414091.jpg" alt="img" />
                                </div>
                                <div className="post-like-cmnt">
                                    <p><IoMdHeart className="icon text-danger" />1.8K</p>
                                    <p><GoComment className="icon" />67</p>
                                </div>
                            </div>
                            <div className="post-box">
                                <div>
                                    <img src="https://www.influglue.com/files/smacrawl/instagram/3710/3044539423270290678.jpg" alt="img" />
                                </div>
                                <div className="post-like-cmnt">
                                    <p><IoMdHeart className="icon text-danger" />1.8K</p>
                                    <p><GoComment className="icon" />67</p>
                                </div>
                            </div>
                            {RecentPostData.map((item, index) => (
                            <div className="post-box">
                                <div>
                                    <img src={item.imgSrc} alt="img" />
                                </div>
                                <div className="post-like-cmnt">
                                    <p><IoMdHeart className="icon text-danger" />{item.likes}</p>
                                    <p><GoComment className="icon" />{item.comments}</p>
                                </div>
                            </div>
                            ))}

                        </div>

                    </div>

                </div>
            </div>

            <div className=" text-center mt-5">
                <h1 className="mb-5 fw-bold">Similar Influencers</h1>
                <div className=" d-flex flex-wrap">
                    {OurinfluencerData.map((item, i) => (
                        <div key={item.id} className="card-box">
                            <Link to={`/influencer-detail/${item.id}`}>
                                <div className={`card-img-div${item.id}`}>
                                    <img src={item.imageUrl} alt="img" />
                                    <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                                </div>
                            </Link>
                            <div className="card-content-div">
                                <h6>{item.name}</h6>
                                <p>{item.city}</p>
                                <div>
                                    {item.categories.map((category) => (
                                        <Button key={category} size="small" variant="outlined">
                                            {category}
                                        </Button>
                                    ))}
                                </div>
                                <div>
                                    <ul>
                                        <li>
                                            <p>{item.followers}</p>
                                            <span>Followers</span>
                                        </li>
                                        <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                            <p>{item.posts}</p>
                                            <span>Posts</span>
                                        </li>
                                        <li>
                                            <p>{item.engagement}</p>
                                            <span>Engagement</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>

            <Footer />
        </>
    )
}

export default InfluencerDetail
