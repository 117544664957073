import { Box } from "@mui/material"
import Footer from "../common/footer/Footer"
import Header from "../common/header/Header"
import '../styles/clientStyle.css'
import { useEffect, useState } from "react"
import axios from "axios"

const OurClients = () => {
    const [client, setClient] = useState()

    useEffect(() => {
        const getClient = async () => {
            const reqClient = await axios.get('https://onlineparttimejobs.in/api/sellerList/public', {
                withCredentials: true
            })
            console.log('client', reqClient.data);
            setClient(reqClient.data)
        }
        getClient();
    }, [])

    return (
        <>
            <Header />
            <Box>
                <h1 className=" text-center m-5 fw-bold">Our Clients</h1>
                <div className=" d-flex flex-wrap justify-content-center">

                    {client?.map((items) => {
                       return(
                        <div className="client-box">
                            <div className="imglogo">
                                <img src="https://www.influglue.com/files/medialibrary/bde6b51ab9293f63ae6652a3db538716.jpg" alt="img" />
                            </div>
                            <div>
                                <h3>{items?.firstname} {items?.lastname}</h3>
                            </div>
                            <div className="img-box">
                                <img src="https://www.influglue.com/files/medialibrary/bcc22d7fea19389cefc2e519fd6ce7b6.JPG" alt="img" />
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <p>2</p>
                                        <span>Influncers</span>
                                    </li>
                                    <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                        <p>2</p>
                                        <span>Posts</span>
                                    </li>
                                    <li>
                                        <p>1.5L</p>
                                        <span>Engagement</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                       )
                    })}
                    {/* <div className="client-box">
                        <div className="imglogo">
                            <img src="https://www.influglue.com/files/medialibrary/bde6b51ab9293f63ae6652a3db538716.jpg" alt="img" />
                        </div>
                        <div>
                            <h3>Johnnie Walker</h3>
                        </div>
                        <div className="img-box">
                            <img src="https://www.influglue.com/files/medialibrary/bcc22d7fea19389cefc2e519fd6ce7b6.JPG" alt="img" />
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <p>2</p>
                                    <span>Influncers</span>
                                </li>
                                <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                    <p>2</p>
                                    <span>Posts</span>
                                </li>
                                <li>
                                    <p>1.5L</p>
                                    <span>Engagement</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="client-box">
                        <div className="imglogo">
                            <img src="https://www.influglue.com/files/medialibrary/c3435e3f2098850daba5d2493123cb18.jpg" alt="img" />
                        </div>
                        <div>
                            <h3>KGF - CHAPTER 2</h3>
                        </div>
                        <div className="img-box">
                            <img src="https://www.influglue.com/files/medialibrary/25218306c5f385282fa39ec9385011d3.jpg" alt="img" />
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <p>6</p>
                                    <span>Influncers</span>
                                </li>
                                <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                    <p>6</p>
                                    <span>Posts</span>
                                </li>
                                <li>
                                    <p>2.5L</p>
                                    <span>Engagement</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="client-box">
                        <div className="imglogo">
                            <img src="https://www.influglue.com/files/medialibrary/7cdf890b219a0489c3b07f83e2e8f719.jpg" alt="img" />
                        </div>
                        <div>
                            <h3>Mc Dowells No 1</h3>
                        </div>
                        <div className="img-box">
                            <img src="https://www.influglue.com/files/medialibrary/1049844475f4b48c6551cf8cb91f2683.jpg" alt="img" />
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <p>7</p>
                                    <span>Influncers</span>
                                </li>
                                <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                    <p>7</p>
                                    <span>Posts</span>
                                </li>
                                <li>
                                    <p>3.5L</p>
                                    <span>Engagement</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="client-box">
                        <div className="imglogo">
                            <img src="https://www.influglue.com/files/medialibrary/f25a30cd563b1d78d280e95aa0901b6b.jpg" alt="img" />
                        </div>
                        <div>
                            <h3>The Tribe Concepts</h3>
                        </div>
                        <div className="img-box">
                            <img src="https://www.influglue.com/files/medialibrary/01be4f3f2b6ecae86de13d4bdfb89d34.jpg" alt="img" />
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <p>2</p>
                                    <span>Influncers</span>
                                </li>
                                <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                    <p>3</p>
                                    <span>Posts</span>
                                </li>
                                <li>
                                    <p>65K</p>
                                    <span>Engagement</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="client-box">
                        <div className="imglogo">
                            <img src="https://www.influglue.com/files/medialibrary/8130c9bd4515e7539255d221dbdf9051.jpg" alt="img" />
                        </div>
                        <div>
                            <h3>Edvoy</h3>
                        </div>
                        <div className="img-box">
                            <img src="https://www.influglue.com/files/medialibrary/0d5536d367e614828833e7fff5cf134a.jpg" alt="img" />
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <p>3</p>
                                    <span>Influncers</span>
                                </li>
                                <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                    <p>6</p>
                                    <span>Posts</span>
                                </li>
                                <li>
                                    <p>1.5L</p>
                                    <span>Engagement</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="client-box">
                        <div className="imglogo">
                            <img src="https://www.influglue.com/files/medialibrary/e0422b0781cdc90e9b54da3f1e4559ea.jpg" alt="img" />
                        </div>
                        <div>
                            <h3>Chingari</h3>
                        </div>
                        <div className="img-box">
                            <img src="https://www.influglue.com/files/medialibrary/ae855ca24d776b6336e3302317956f82.jpg" alt="img" />
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <p>2</p>
                                    <span>Influncers</span>
                                </li>
                                <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                    <p>5</p>
                                    <span>Posts</span>
                                </li>
                                <li>
                                    <p>135K</p>
                                    <span>Engagement</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="client-box">
                        <div className="imglogo">
                            <img src="https://www.influglue.com/files/medialibrary/b287c19eb89a2a8e9fd225b9862b453f.jpg" alt="img" />
                        </div>
                        <div>
                            <h3>Supply6</h3>
                        </div>
                        <div className="img-box">
                            <img src="https://www.influglue.com/files/medialibrary/24b0267ebb0c4ed63f2d0ab8eab5e297.jpg" alt="img" />
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <p>4</p>
                                    <span>Influncers</span>
                                </li>
                                <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                    <p>2</p>
                                    <span>Posts</span>
                                </li>
                                <li>
                                    <p>1L</p>
                                    <span>Engagement</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="client-box">
                        <div className="imglogo">
                            <img src="https://www.influglue.com/files/medialibrary/d885f33c863a972d9817f1b569b6116b.jpg" alt="img" />
                        </div>
                        <div>
                            <h3>Get Contact</h3>
                        </div>
                        <div className="img-box">
                            <img src="https://www.influglue.com/files/medialibrary/ad8a7cf1a356ce43e0e25ba58a94ffc1.jpg" alt="img" />
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <p>2</p>
                                    <span>Influncers</span>
                                </li>
                                <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                    <p>2</p>
                                    <span>Posts</span>
                                </li>
                                <li>
                                    <p>1.5L</p>
                                    <span>Engagement</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="client-box">
                        <div className="imglogo">
                            <img src="https://www.influglue.com/files/medialibrary/9f91207b5a1035b955c5e34a54071014.jpg" alt="img" />
                        </div>
                        <div>
                            <h3>Sarovar Hotels & Resorts</h3>
                        </div>
                        <div className="img-box">
                            <img src="https://www.influglue.com/files/medialibrary/805161ef425df7687c792738b1076ed5.jpg" alt="img" />
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <p>2</p>
                                    <span>Influncers</span>
                                </li>
                                <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                    <p>2</p>
                                    <span>Posts</span>
                                </li>
                                <li>
                                    <p>1.5L</p>
                                    <span>Engagement</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="client-box">
                        <div className="imglogo">
                            <img src="https://www.influglue.com/files/medialibrary/6122865f604fd17783c80c0cef667ac8.jpg" alt="img" />
                        </div>
                        <div>
                            <h3>Little Leap</h3>
                        </div>
                        <div className="img-box">
                            <img src="https://www.influglue.com/files/medialibrary/30951241cb6645111920f0fde8082ad8.jpg" alt="img" />
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <p>2</p>
                                    <span>Influncers</span>
                                </li>
                                <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                    <p>2</p>
                                    <span>Posts</span>
                                </li>
                                <li>
                                    <p>1.5L</p>
                                    <span>Engagement</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="client-box">
                        <div className="imglogo">
                            <img src="https://www.influglue.com/files/medialibrary/8e8ccc0a0f73e04fa76ad28c085e1eec.jpg" alt="img" />
                        </div>
                        <div>
                            <h3>21CC Education</h3>
                        </div>
                        <div className="img-box">
                            <img src="https://www.influglue.com/files/medialibrary/fc319eb1df8d11e5076412312dab6b77.jpg" alt="img" />
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <p>2</p>
                                    <span>Influncers</span>
                                </li>
                                <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                    <p>2</p>
                                    <span>Posts</span>
                                </li>
                                <li>
                                    <p>1.5L</p>
                                    <span>Engagement</span>
                                </li>
                            </ul>
                        </div>
                    </div> */}

                </div>
            </Box>
            <Footer />
        </>
    )
}

export default OurClients
