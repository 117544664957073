import React, { Component } from "react";
import Slider from "react-slick";
import "./OurSlider.css";

function OurWorkSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
  };
  return (
    <>
      <div className="OurWorkSlider">
        <div className="mainWpOurwork">
          <h2>Some of Our Work</h2>
          <div>
            <Slider {...settings}>
              <div>
                <div className="clientWorkblock">
                  <div className="logoholder">
                    <img src="https://www.influglue.com/files/medialibrary/bde6b51ab9293f63ae6652a3db538716.jpg" />
                  </div>
                  <div className="logoTitle">Johnnie Walker</div>
                  <div className="infoimg-folder">
                    <img src="https://www.influglue.com/files/medialibrary/bcc22d7fea19389cefc2e519fd6ce7b6.JPG" />
                  </div>
                  <ul className="abarisnfluencer">
                    <li className="borderRight">
                      2<span className="spnabaris">Influncers</span>
                    </li>
                    <li className="borderRight">
                      2<span className="spnabaris">Posts</span>
                    </li>
                    <li>
                      1.5L<span className="spnabaris">Engagement</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div className="clientWorkblock">
                  <div className="logoholder">
                    <img src="https://www.influglue.com/files/medialibrary/c3435e3f2098850daba5d2493123cb18.jpg" />
                  </div>
                  <div className="logoTitle">KGF - CHAPTER 2</div>
                  <div className="infoimg-folder">
                    <img src="https://www.influglue.com/files/medialibrary/25218306c5f385282fa39ec9385011d3.jpg" />
                  </div>
                  <ul className="abarisnfluencer">
                    <li className="borderRight">
                      6<span className="spnabaris">Influncers</span>
                    </li>
                    <li className="borderRight">
                      6<span className="spnabaris">Posts</span>
                    </li>
                    <li>
                      2.8L<span className="spnabaris">Engagement</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div className="clientWorkblock">
                  <div className="logoholder">
                    <img src="https://www.influglue.com/files/medialibrary/7cdf890b219a0489c3b07f83e2e8f719.jpg" />
                  </div>
                  <div className="logoTitle">Mc Dowells No 1</div>
                  <div className="infoimg-folder">
                    <img src="https://www.influglue.com/files/medialibrary/1049844475f4b48c6551cf8cb91f2683.jpg" />
                  </div>
                  <ul className="abarisnfluencer">
                    <li className="borderRight">
                      7<span className="spnabaris">Influncers</span>
                    </li>
                    <li className="borderRight">
                      7<span className="spnabaris">Posts</span>
                    </li>
                    <li>
                      1.5L<span className="spnabaris">Engagement</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div className="clientWorkblock">
                  <div className="logoholder">
                    <img src="https://www.influglue.com/files/medialibrary/f25a30cd563b1d78d280e95aa0901b6b.jpg" />
                  </div>
                  <div className="logoTitle">The Tribe Concepts</div>
                  <div className="infoimg-folder">
                    <img src="https://www.influglue.com/files/medialibrary/01be4f3f2b6ecae86de13d4bdfb89d34.jpg" />
                  </div>
                  <ul className="abarisnfluencer">
                    <li className="borderRight">
                      2<span className="spnabaris">Influncers</span>
                    </li>
                    <li className="borderRight">
                      3<span className="spnabaris">Posts</span>
                    </li>
                    <li>
                      68k<span className="spnabaris">Engagement</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div className="clientWorkblock">
                  <div className="logoholder">
                    <img src="https://www.influglue.com/files/medialibrary/8130c9bd4515e7539255d221dbdf9051.jpg" />
                  </div>
                  <div className="logoTitle">Edvoy</div>
                  <div className="infoimg-folder">
                    <img src="https://www.influglue.com/files/medialibrary/0d5536d367e614828833e7fff5cf134a.jpg" />
                  </div>
                  <ul className="abarisnfluencer">
                    <li className="borderRight">
                      3<span className="spnabaris">Influncers</span>
                    </li>
                    <li className="borderRight">
                      6<span className="spnabaris">Posts</span>
                    </li>
                    <li>
                      1.2l<span className="spnabaris">Engagement</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div className="clientWorkblock">
                  <div className="logoholder">
                    <img src="https://www.influglue.com/files/medialibrary/b287c19eb89a2a8e9fd225b9862b453f.jpg" />
                  </div>
                  <div className="logoTitle">Supply6</div>
                  <div className="infoimg-folder">
                    <img src="https://www.influglue.com/files/medialibrary/24b0267ebb0c4ed63f2d0ab8eab5e297.jpg" />
                  </div>
                  <ul className="abarisnfluencer">
                    <li className="borderRight">
                      3<span className="spnabaris">Influncers</span>
                    </li>
                    <li className="borderRight">
                      5<span className="spnabaris">Posts</span>
                    </li>
                    <li>
                      65k<span className="spnabaris">Engagement</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div className="clientWorkblock">
                  <div className="logoholder">
                    <img src="https://www.influglue.com/files/medialibrary/d885f33c863a972d9817f1b569b6116b.jpg" />
                  </div>
                  <div className="logoTitle">Get Contact</div>
                  <div className="infoimg-folder">
                    <img src="https://www.influglue.com/files/medialibrary/ad8a7cf1a356ce43e0e25ba58a94ffc1.jpg" />
                  </div>
                  <ul className="abarisnfluencer">
                    <li className="borderRight">
                      3<span className="spnabaris">Influncers</span>
                    </li>
                    <li className="borderRight">
                      6<span className="spnabaris">Posts</span>
                    </li>
                    <li>
                      2L<span className="spnabaris">Engagement</span>
                    </li>
                  </ul>
                </div>
              </div>
            </Slider>
          </div>
          <a href="" className="startbtnrt">
            Start A Campaign
          </a>
        </div>
      </div>
    </>
  );
}
export default OurWorkSlider;
