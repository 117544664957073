import "./Books.css";
import img from "../../assest/images/5c315600c40f10d6be2955f027f1dd64.jpg";
import thumb from "../../assest/images/c758472355774214074844792e85803d.jpg";
import img1 from "../../assest/images/21242f83fdb958dd924db0c7140efb18.jpg";
import img2 from "../../assest/images/1c8cd4886228dc8aa86b6c4e0dc7f53e.png";
import img3 from "../../assest/images/6258c461596fc32d5f0d82fa0cc6eb72.png";
import img4 from "../../assest/images/1977ae41dbef73b6f974d37d3f690652.png";
import img5 from "../../assest/images/5d1dd7a6e9e70d5fa74284caaac77dc1.png";
import img6 from "../../assest/images/40906e114fecc81731974fbddfa350f0.png";
import img7 from "../../assest/images/14e0946fa0e1f29069b984521acc6225.png";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import { Favorite, FavoriteBorder } from "@mui/icons-material";


// import { Favorite, FavoriteBorder } from "@mui/icons-material";
// import { Box, Button, Checkbox } from "@mui/material"
// import { IoLocationOutline } from "react-icons/io5";
// import { Link } from "react-router-dom";
import FreqAskQues from "../../components/FreqAskQues";
import Header from "../../common/header/Header";
import { Box, Checkbox } from "@mui/material";
import { Link } from "react-router-dom";


function Book() {
  return (
    <>
      <Header />
      <section className="bookHolderBanner">
        <img src={img} alt="" className="img-fluid" />
        <div className="container bookBannerContent ">
          <div className="content_box">
            <h1>
              Influencer Marketing for
              <span>Book</span>
              Promotion{" "}
            </h1>
            <h2>
              Promote your books with our state-of-the-art influencer marketing
              services and experience an amazing boost in your sales. We design
              top-notch campaigns to draw maximum attention to your venture from
              your target audience.
            </h2>
          </div>
        </div>
      </section>

      <section className="cat_about_block">
        <div className="container .">
          <div className="inner_block">
            <div className="content_sec">
              <h3>Online Endorsement Of Books</h3>
              <p>
                As a leading influencer marketing platform in India, we work
                with famous bloggers and book reviewers who are well known for
                creating high-class visual content. Our influencers are verified
                and helped various ventures to achieve their marketing
                requirement with their unique approach. If you are looking to
                endorse your books, get in touch with us now. We will popularize
                your books most creatively in no time.
                <br />
                <br />
                Influencer marketing is the most effective form of online
                marketing that has huge potential to upscale a venture. We will
                select proper influencers for your venture who can endorse your
                books with our splendid campaigns. The best part of working with
                us is you can measure your campaign with our latest analytics
                tools, which will help in better future content planning.
              </p>
            </div>
            <div className=".image_content_sec">
              <div className="thumbnail_block">
                <img src={thumb} className="img-fluid" alt="" />
              </div>
              <div className="inner_imgblock">
                {" "}
                <img src={img1} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home_service_block">
        <div className="container main_wrapper">
          <div className="searvice_block">
            <h2>Bespoke Influencer Marketing Services</h2>
            <p>
              As a leading Influencer Marketing Platform in India,
              <br />
              we offer dynamic influencer marketing services, that includes
            </p>
            <div className="inner_service_block">
              <div className="block">
                <a href="/">
                  <div className="iconblock">
                    <img
                      src={img2}
                      alt="Influencer Marketing"
                      className="lazy"
                    />
                  </div>
                  <h4>Influencer Marketing</h4>
                </a>
              </div>
              <div className="block">
                <a href="/">
                  <div className="iconblock">
                    <img
                      src={img3}
                      alt="Social Media Marketing"
                      className="lazy"
                    />
                  </div>
                  <h4>Social Media Marketing</h4>
                </a>
              </div>
              <div className="block">
                <a href="/">
                  <div className="iconblock">
                    <img src={img4} alt="Content Marketing" className="lazy" />
                  </div>
                  <h4>Content Marketing</h4>
                </a>
              </div>
              <div className="block">
                <a href="/">
                  <div className="iconblock">
                    <img src={img5} alt="Brand Promotion" className="lazy" />
                  </div>
                  <h4>Brand Promotion</h4>
                </a>
              </div>
              <div className="block">
                <a href="/">
                  <div className="iconblock">
                    <img src={img6} alt="Product Reviews" className="lazy" />
                  </div>
                  <h4>Product Reviews</h4>
                </a>
              </div>
              <div className="block">
                <a href="/">
                  <div className="iconblock">
                    <img src={img7} alt="Meme Marketing" className="lazy" />
                  </div>
                  <h4>Meme Marketing</h4>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bottom_two_gray_block">
        <div className="conatiner main_wrapper">
          <div className="inner_content_block">
            <div className="how_it_holder">
              <h3>How Influencers Can Help</h3>
              <ul className="inner_block">
                <li>
                  <div className="inner">
                    Create static and carousel posts on Instagram/ Facebook with
                    your books.{" "}
                  </div>
                </li>
                <li>
                  <div className="inner">
                    Create Video posts on Instagram/ Facebook with your books.
                  </div>
                </li>
                <li>
                  <div className="inner">
                    Buy books and other products from your website, create an
                    unboxing video, share the post with their followers, and
                    submit honest online reviews.
                  </div>
                </li>
                <li>
                  <div className="inner">
                    Physically Visit your store from a particular locality and
                    create a story about the store highlighting their
                    collection.
                  </div>
                </li>
                <li>
                  <div className="inner">
                    Create and Manage Instagram Contest (Giveaway or other) and
                    help increase Facebook and Instagram Followers of the
                    concerned brand.
                  </div>
                </li>
              </ul>
            </div>
            <div className="lead_capture_form">
              <div className="signup_frm">
                <div className="inner">
                  <form action="formBasicDetails">
                    <div className="col-md-12 ">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="First Name*"
                        className="mb-3"
                      >
                        <Form.Control type="text" placeholder="first name" />
                      </FloatingLabel>
                    </div>
                    <div className="col-md-12 ">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Last Name*"
                        className="mb-3"
                      >
                        <Form.Control type="text" placeholder="first name" />
                      </FloatingLabel>
                    </div>
                    <div className="col-md-12 ">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Email*"
                        className="mb-3"
                      >
                        <Form.Control type="text" placeholder="first name" />
                      </FloatingLabel>
                    </div>
                    <div className="col-md-12 ">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Enter 10 digit mobile number*"
                        className="mb-3"
                      >
                        <Form.Control type="text" placeholder="first name" />
                        <small style={{ display: "flex" }}>
                          <strong>Note:</strong> Mobile number should not
                          include +91
                        </small>
                      </FloatingLabel>
                    </div>
                    <div className="col-md-12 selectGender">
                      <ul>
                        <li>I am*</li>
                        <li>
                          <Form.Check type="radio" label="Male" />
                        </li>
                        <li>
                          <Form.Check type="radio" label="female" />
                        </li>
                      </ul>
                    </div>
                    <div
                      className="col-md-12 "
                      style={{ marginBottom: "13px" }}
                    >
                      <Form.Select
                        size="lg"
                        aria-label="Default select example"
                      >
                        <option>Select City*</option>
                        <option value="1">Agra</option>
                        <option value="2">Delhi</option>
                        <option value="3">Mumbai</option>
                      </Form.Select>
                    </div>
                    <div
                      className="col-md-12 "
                      style={{ marginBottom: "13px" }}
                    >
                      <Form.Select
                        size="lg"
                        aria-label="Default select example"
                      >
                        <option>Select Birth Year*</option>
                        <option value="1">2023</option>
                        <option value="2">2022</option>
                        <option value="3">2021</option>
                      </Form.Select>
                    </div>
                    <div className="col-md-12 " id="proceedBtn">
                      <Button variant="primary" size="lg">
                        PROCEED
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <a href="/" className="greenbtn startcamplanding">
            Download Rate Card for Books Influencers
          </a>
        </div>
      </section>

      {/* <section className="topinfluencers">
        <div className="similar_influencers">
          <h2>Top Books Influencers in India</h2>
          <div className=" d-flex flex-wrap">
                            <div className="card-box">
                                <Link to={'/influencer-detail'}>
                                    <div className="card-img-div1">
                                        <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                                        <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                                    </div>
                                </Link>
                                <div className="card-content-div">
                                    <h6>BUSHRA</h6>
                                    <p>Delhi</p>
                                    <div>
                                        <Button size="small" variant="outlined">Health</Button>
                                        <Button size="small" variant="outlined">Lifestyle</Button>
                                        <Button size="small" variant="outlined">Parenting</Button>
                                        <Button size="small" variant="outlined">Education</Button>
                                        <Button size="small" variant="outlined">Food</Button>
                                        <Button size="small" variant="outlined">Mom</Button>
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <p>35.6K</p>
                                                <span>Followers</span>
                                            </li>
                                            <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                                <p> 1.3K</p>
                                                <span>Posts</span>
                                            </li>
                                            <li>
                                                <p>1.03%</p>
                                                <span>Engagement</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card-box">
                                <div className="card-img-div2">
                                    <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                                    <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                                </div>
                                <div className="card-content-div">
                                    <h6>KRITIKA</h6>
                                    <p>Delhi</p>
                                    <div>
                                        <Button size="small" variant="outlined">Beauty</Button>
                                        <Button size="small" variant="outlined">Lifestyle</Button>
                                        <Button size="small" variant="outlined">Women's Fashion</Button>
                                        <Button size="small" variant="outlined">Travel</Button>
                                        <Button size="small" variant="outlined">Entertainments</Button>
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <p>1.8M</p>
                                                <span>Followers</span>
                                            </li>
                                            <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                                <p> 6.4K</p>
                                                <span>Posts</span>
                                            </li>
                                            <li>
                                                <p>2.63%</p>
                                                <span>Engagement</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card-box">
                                <div className="card-img-div3">
                                    <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                                    <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                                </div>
                                <div className="card-content-div">
                                    <h6>SANDY</h6>
                                    <p>Bangalore</p>
                                    <div>
                                        <Button size="small" variant="outlined">Couple</Button>
                                        <Button size="small" variant="outlined">Lifestyle</Button>
                                        <Button size="small" variant="outlined">Travel</Button>
                                        <Button size="small" variant="outlined">Photography</Button>
                                        <Button size="small" variant="outlined">Food</Button>
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <p>39.6K</p>
                                                <span>Followers</span>
                                            </li>
                                            <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                                <p> 630</p>
                                                <span>Posts</span>
                                            </li>
                                            <li>
                                                <p>0.53%</p>
                                                <span>Engagement</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card-box">
                                <div className="card-img-div4">
                                    <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                                    <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                                </div>
                                <div className="card-content-div">
                                    <h6>PRERNA</h6>
                                    <p>Mumbai</p>
                                    <div>
                                        <Button size="small" variant="outlined">Women's Fashion</Button>
                                        <Button size="small" variant="outlined">Lifestyle</Button>
                                        <Button size="small" variant="outlined">Parenting</Button>
                                        <Button size="small" variant="outlined">Travel</Button>
                                        <Button size="small" variant="outlined">Fitness</Button>
                                        <Button size="small" variant="outlined">Mom</Button>
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <p>85.6K</p>
                                                <span>Followers</span>
                                            </li>
                                            <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                                <p> 3.3K</p>
                                                <span>Posts</span>
                                            </li>
                                            <li>
                                                <p>1.53%</p>
                                                <span>Engagement</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card-box">
                                <div className="card-img-div5">
                                    <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                                    <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                                </div>
                                <div className="card-content-div">
                                    <h6>Deepa</h6>
                                    <p>Bangalore</p>
                                    <div>
                                        <Button size="small" variant="outlined">Health</Button>
                                        <Button size="small" variant="outlined">Lifestyle</Button>
                                        <Button size="small" variant="outlined">Parenting</Button>
                                        <Button size="small" variant="outlined">Education</Button>
                                        <Button size="small" variant="outlined">Food</Button>
                                        <Button size="small" variant="outlined">Mom</Button>
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <p>35.6K</p>
                                                <span>Followers</span>
                                            </li>
                                            <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                                <p> 1.3K</p>
                                                <span>Posts</span>
                                            </li>
                                            <li>
                                                <p>1.03%</p>
                                                <span>Engagement</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card-box">
                                <div className="card-img-div6">
                                    <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                                    <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                                </div>
                                <div className="card-content-div">
                                    <h6>DIMPLE</h6>
                                    <p>Mumbai</p>
                                    <div>
                                        <Button size="small" variant="outlined">Health</Button>
                                        <Button size="small" variant="outlined">Lifestyle</Button>
                                        <Button size="small" variant="outlined">Beauty</Button>
                                        <Button size="small" variant="outlined">Travel</Button>
                                        <Button size="small" variant="outlined">Mom</Button>
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <p>35.6K</p>
                                                <span>Followers</span>
                                            </li>
                                            <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                                <p> 1.3K</p>
                                                <span>Posts</span>
                                            </li>
                                            <li>
                                                <p>1.03%</p>
                                                <span>Engagement</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
        </div>
      </section> */}

      <Box>
        <h1 className=" text-center m-5 fw-bold">Top Books Influencers in India</h1>
        <div className=" d-flex flex-wrap justify-content-center">
          <div className="cardbox m-3">
            <Link to={'/influencer-detail'}>
              <div className="cardimg-div1">
                <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
              </div>
            </Link>
            <div className="cardcontent-div">
              <h6>BUSHRA</h6>
              <p>Delhi</p>
              <div>
                <Button size="small" variant="outlined">Health</Button>
                <Button size="small" variant="outlined">Lifestyle</Button>
                <Button size="small" variant="outlined">Parenting</Button>
                <Button size="small" variant="outlined">Education</Button>
                <Button size="small" variant="outlined">Food</Button>
                <Button size="small" variant="outlined">Mom</Button>
              </div>
              <div>
                <ul>
                  <li>
                    <p>35.6K</p>
                    <span>Followers</span>
                  </li>
                  <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                    <p> 1.3K</p>
                    <span>Posts</span>
                  </li>
                  <li>
                    <p>1.03%</p>
                    <span>Engagement</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="cardbox m-3">
            <div className="cardimg-div2">
              <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
              <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
            </div>
            <div className="cardcontent-div">
              <h6>KRITIKA</h6>
              <p>Delhi</p>
              <div>
                <Button size="small" variant="outlined">Beauty</Button>
                <Button size="small" variant="outlined">Lifestyle</Button>
                <Button size="small" variant="outlined">Women's Fashion</Button>
                <Button size="small" variant="outlined">Travel</Button>
                <Button size="small" variant="outlined">Entertainments</Button>
              </div>
              <div>
                <ul>
                  <li>
                    <p>1.8M</p>
                    <span>Followers</span>
                  </li>
                  <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                    <p> 6.4K</p>
                    <span>Posts</span>
                  </li>
                  <li>
                    <p>2.63%</p>
                    <span>Engagement</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="cardbox m-3">
            <div className="cardimg-div3">
              <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
              <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
            </div>
            <div className="cardcontent-div">
              <h6>SANDY</h6>
              <p>Bangalore</p>
              <div>
                <Button size="small" variant="outlined">Couple</Button>
                <Button size="small" variant="outlined">Lifestyle</Button>
                <Button size="small" variant="outlined">Travel</Button>
                <Button size="small" variant="outlined">Photography</Button>
                <Button size="small" variant="outlined">Food</Button>
              </div>
              <div>
                <ul>
                  <li>
                    <p>39.6K</p>
                    <span>Followers</span>
                  </li>
                  <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                    <p> 630</p>
                    <span>Posts</span>
                  </li>
                  <li>
                    <p>0.53%</p>
                    <span>Engagement</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="cardbox m-3">
            <div className="cardimg-div4">
              <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
              <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
            </div>
            <div className="cardcontent-div">
              <h6>PRERNA</h6>
              <p>Mumbai</p>
              <div>
                <Button size="small" variant="outlined">Women's Fashion</Button>
                <Button size="small" variant="outlined">Lifestyle</Button>
                <Button size="small" variant="outlined">Parenting</Button>
                <Button size="small" variant="outlined">Travel</Button>
                <Button size="small" variant="outlined">Fitness</Button>
                <Button size="small" variant="outlined">Mom</Button>
              </div>
              <div>
                <ul>
                  <li>
                    <p>85.6K</p>
                    <span>Followers</span>
                  </li>
                  <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                    <p> 3.3K</p>
                    <span>Posts</span>
                  </li>
                  <li>
                    <p>1.53%</p>
                    <span>Engagement</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="cardbox m-3">
            <div className="cardimg-div5">
              <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
              <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
            </div>
            <div className="cardcontent-div">
              <h6>Deepa</h6>
              <p>Bangalore</p>
              <div>
                <Button size="small" variant="outlined">Health</Button>
                <Button size="small" variant="outlined">Lifestyle</Button>
                <Button size="small" variant="outlined">Parenting</Button>
                <Button size="small" variant="outlined">Education</Button>
                <Button size="small" variant="outlined">Food</Button>
                <Button size="small" variant="outlined">Mom</Button>
              </div>
              <div>
                <ul>
                  <li>
                    <p>35.6K</p>
                    <span>Followers</span>
                  </li>
                  <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                    <p> 1.3K</p>
                    <span>Posts</span>
                  </li>
                  <li>
                    <p>1.03%</p>
                    <span>Engagement</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="cardbox m-3">
            <div className="cardimg-div6">
              <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
              <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
            </div>
            <div className="cardcontent-div">
              <h6>DIMPLE</h6>
              <p>Mumbai</p>
              <div>
                <Button size="small" variant="outlined">Health</Button>
                <Button size="small" variant="outlined">Lifestyle</Button>
                <Button size="small" variant="outlined">Beauty</Button>
                <Button size="small" variant="outlined">Travel</Button>
                <Button size="small" variant="outlined">Mom</Button>
              </div>
              <div>
                <ul>
                  <li>
                    <p>35.6K</p>
                    <span>Followers</span>
                  </li>
                  <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                    <p> 1.3K</p>
                    <span>Posts</span>
                  </li>
                  <li>
                    <p>1.03%</p>
                    <span>Engagement</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="cardbox m-3">
            <Link to={'/influencer-detail'}>
              <div className="cardimg-div1">
                <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
              </div>
            </Link>
            <div className="cardcontent-div">
              <h6>BUSHRA</h6>
              <p>Delhi</p>
              <div>
                <Button size="small" variant="outlined">Health</Button>
                <Button size="small" variant="outlined">Lifestyle</Button>
                <Button size="small" variant="outlined">Parenting</Button>
                <Button size="small" variant="outlined">Education</Button>
                <Button size="small" variant="outlined">Food</Button>
                <Button size="small" variant="outlined">Mom</Button>
              </div>
              <div>
                <ul>
                  <li>
                    <p>35.6K</p>
                    <span>Followers</span>
                  </li>
                  <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                    <p> 1.3K</p>
                    <span>Posts</span>
                  </li>
                  <li>
                    <p>1.03%</p>
                    <span>Engagement</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="cardbox m-3">
            <div className="cardimg-div2">
              <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
              <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
            </div>
            <div className="cardcontent-div">
              <h6>KRITIKA</h6>
              <p>Delhi</p>
              <div>
                <Button size="small" variant="outlined">Beauty</Button>
                <Button size="small" variant="outlined">Lifestyle</Button>
                <Button size="small" variant="outlined">Women's Fashion</Button>
                <Button size="small" variant="outlined">Travel</Button>
                <Button size="small" variant="outlined">Entertainments</Button>
              </div>
              <div>
                <ul>
                  <li>
                    <p>1.8M</p>
                    <span>Followers</span>
                  </li>
                  <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                    <p> 6.4K</p>
                    <span>Posts</span>
                  </li>
                  <li>
                    <p>2.63%</p>
                    <span>Engagement</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="cardbox m-3">
            <div className="cardimg-div3">
              <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
              <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
            </div>
            <div className="cardcontent-div">
              <h6>SANDY</h6>
              <p>Bangalore</p>
              <div>
                <Button size="small" variant="outlined">Couple</Button>
                <Button size="small" variant="outlined">Lifestyle</Button>
                <Button size="small" variant="outlined">Travel</Button>
                <Button size="small" variant="outlined">Photography</Button>
                <Button size="small" variant="outlined">Food</Button>
              </div>
              <div>
                <ul>
                  <li>
                    <p>39.6K</p>
                    <span>Followers</span>
                  </li>
                  <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                    <p> 630</p>
                    <span>Posts</span>
                  </li>
                  <li>
                    <p>0.53%</p>
                    <span>Engagement</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="cardbox m-3">
            <div className="cardimg-div4">
              <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
              <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
            </div>
            <div className="cardcontent-div">
              <h6>PRERNA</h6>
              <p>Mumbai</p>
              <div>
                <Button size="small" variant="outlined">Women's Fashion</Button>
                <Button size="small" variant="outlined">Lifestyle</Button>
                <Button size="small" variant="outlined">Parenting</Button>
                <Button size="small" variant="outlined">Travel</Button>
                <Button size="small" variant="outlined">Fitness</Button>
                <Button size="small" variant="outlined">Mom</Button>
              </div>
              <div>
                <ul>
                  <li>
                    <p>85.6K</p>
                    <span>Followers</span>
                  </li>
                  <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                    <p> 3.3K</p>
                    <span>Posts</span>
                  </li>
                  <li>
                    <p>1.53%</p>
                    <span>Engagement</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="cardbox m-3">
            <div className="cardimg-div5">
              <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
              <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
            </div>
            <div className="cardcontent-div">
              <h6>Deepa</h6>
              <p>Bangalore</p>
              <div>
                <Button size="small" variant="outlined">Health</Button>
                <Button size="small" variant="outlined">Lifestyle</Button>
                <Button size="small" variant="outlined">Parenting</Button>
                <Button size="small" variant="outlined">Education</Button>
                <Button size="small" variant="outlined">Food</Button>
                <Button size="small" variant="outlined">Mom</Button>
              </div>
              <div>
                <ul>
                  <li>
                    <p>35.6K</p>
                    <span>Followers</span>
                  </li>
                  <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                    <p> 1.3K</p>
                    <span>Posts</span>
                  </li>
                  <li>
                    <p>1.03%</p>
                    <span>Engagement</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="cardbox m-3">
            <div className="cardimg-div6">
              <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
              <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
            </div>
            <div className="cardcontent-div">
              <h6>DIMPLE</h6>
              <p>Mumbai</p>
              <div>
                <Button size="small" variant="outlined">Health</Button>
                <Button size="small" variant="outlined">Lifestyle</Button>
                <Button size="small" variant="outlined">Beauty</Button>
                <Button size="small" variant="outlined">Travel</Button>
                <Button size="small" variant="outlined">Mom</Button>
              </div>
              <div>
                <ul>
                  <li>
                    <p>35.6K</p>
                    <span>Followers</span>
                  </li>
                  <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                    <p> 1.3K</p>
                    <span>Posts</span>
                  </li>
                  <li>
                    <p>1.03%</p>
                    <span>Engagement</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Box>

      <section>
        <div><FreqAskQues /></div>
      </section>
    </>
  );
}
export default Book;
