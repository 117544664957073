import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from './page/home/Homes'
import OurInfluencers from "./page/OurInfluencers";
import InfluencerDetail from "./page/InfluencerDetail";
import AdvertiserSignUp from "./page/AdvertiserSignUp";
import Services from "./page/Services";
import Campaigns from "./page/Campaigns";
import Contact from './page/Contact/Contact'
import Blog from './page/Blog/Blog'
import AboutUs from "./page/aboutUs/AboutUs";
import Faq from "./page/faq/Faq";
import HowItsWork from "./page/howItsWork/HowItsWork";
import InstagramPackage from "./page/InstagramPackage";
import Testimonials from "./page/Testimonials";
import CampaignDetail from "./page/CampaignDetail";
import Sidebar from "./components/SideAbleMenu/SideBar";
import Jobs from "./page/jobs/Jobs";
import GuestPosts from "./page/guestPosts/GuestPosts";
import YoutubePackage from "./page/YoutubePackage";
import ContentMarketing from "./page/contentMarketing/ContentMarketing";
import Book from "./page/Books/Books";
import OurClients from "./page/OurClients";
import InfluencerSignup from "./page/InfluencerSignup/InfluencerSignup";
import LoginStep1 from "./page/LoginStep1";
import SignUp from "./page/SignUp";
import FemaleInfluencer from "./page/FemaleInfluencer/FemaleInfluencer";
import FaqInfluenser from "./components/FaqInfluencer";
import AdvertiserSignUpMoreDetails from "./page/AdvertiserSignUpMoreDetails";

function App() {
  return (
    <>
     <BrowserRouter>
     <Sidebar/>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/our-influencers' element={<OurInfluencers />} />
          <Route path='/influencer-detail/:id/:name' element={<InfluencerDetail />} />
          <Route path='/advertiser-signup' element={<AdvertiserSignUp />} />
          <Route path='/advertiser-signup/more-details/:token' element={<AdvertiserSignUpMoreDetails />} />
          <Route path='/service' element={<Services />} />
          <Route path='/campaigns' element={<Campaigns/>} />
          <Route path='/contact' element={<Contact/>} />
          <Route path='/blog' element={<Blog/>} />
          <Route path='/about' element={<AboutUs/>} />
          <Route path='/faq' element={<Faq/>} />
          <Route path='/faq/influencer/:id' element={<FaqInfluenser/>} />
          <Route path='/how-its-work' element={<HowItsWork/>} />
          <Route path='/instagram-plan' element={<InstagramPackage/>} />
          <Route path='/youtube-plan' element={<YoutubePackage/>} />
          <Route path='/guest-posts' element={<GuestPosts/>} />
          <Route path='/testimonials' element={<Testimonials/>} />
          <Route path='/campaign-detail' element={<CampaignDetail/>} />
          <Route path='/jobs' element={<Jobs/>} />
          <Route path='/content-marketing' element={<ContentMarketing/>} />
          <Route path='/category/books' element={<Book/>} />
          <Route path='/our-client' element={<OurClients/>} />
          <Route path='/influencer-signup' element={<InfluencerSignup/>} />
          <Route path='/sign-in' element={<LoginStep1/>} />
          <Route path='/sign-up' element={<SignUp/>} />
          <Route path='/blog/top-10-female-fashion-influencers-in-india-in-2024' element={<FemaleInfluencer/>} />

        </Routes>
      </BrowserRouter>
     
    </>
  );
}

export default App;
