import React from "react";
import "./TrustedByBussiness.css";
function TrustedByBussiness() {
  return (
    <>
      <div className="brandblock">
        <div className="brandblockmainwapper">
          <h2>Trusted by Business Owners</h2>
          <ul>
            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/79b33225cf6f0ef0945a8a19cd209131.png"
                alt="flipkart"
                style={{ width: 178, height: 98 }}
              />
            </li>
            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/5bd857413e42b3aaa40965073a40a93d.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>
            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/8e71bb8fb1655aacd10294f78f7924ed.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>
            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/0efb4d2175a461a7006cca9fa2c65599.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>
            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/ea14334910b16c7ebb8dfefde23a5a94.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>
            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/250f711c8130e5941b8c65f285b72409.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>
            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/08121a756924f9ebe24054e0b7cfd69d.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>
            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/b6948b31e8b3da89346f50ff81685384.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>
            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/38791e6dc8c722ecf3496b716f0a0c32.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>
            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/3d96902b196bb85c43c8e943ea1037e2.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>
            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/77a08353f3bebbcdbbdf1f5eec1d927d.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>
            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/fe3bf37c659b5bb88ffe427149d780c1.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>
            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/3e36600dd432d87001ec1155660236a6.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>
            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/4de948bd52d00cf826672889fbf192eb.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>
            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/00b12cd5b7aa2f04cee5b9e4ce281dfc.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>
            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/8c1a09520da6d2bb1e49137827ab1b64.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>
            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/9ccd73bb6435c7a07c9e4859c5acc431.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>
            <li>
              <img
                src="https://www.influglue.com/files/medialibrary/601d1f068ab09fa975355ed6230b5413.png"
                alt="brand"
                style={{ width: 178, height: 98 }}
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
export default TrustedByBussiness;
