import { Box, Button, TextField } from "@mui/material"
import Footer from "../common/footer/Footer"
import Header from "../common/header/Header"
import '../styles/loginStep1Style.css'
import { RiAccountBoxFill } from "react-icons/ri";
import { GiSuitcase } from "react-icons/gi";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";

const LoginStep1 = () => {
    return (
        <>
            <Header />
            <Box className='bg-img-box'>
                <div className="bg-box1">
                    <h2>Abaris SoftTech</h2>
                    <p>New Member Sign Up</p>
                    <div className="btn-div-box">
                        <RiAccountBoxFill className="icon" />
                        <Link to={'/influencer-signup'}>
                            <Button>Sign Up as Influencer</Button>
                        </Link>
                    </div>
                    <div className="btn-div-box">
                        <GiSuitcase className="icon" />
                        <Link to={'/advertiser-signup'}>
                            <Button>Sign Up as Advertiser</Button>
                        </Link>
                    </div>
                </div>
                <div className="bg-box2">
                    <h3>Existing Member Sign In</h3>
                    <div className=" d-flex">
                        <MdEmail className="icon3" />
                        <TextField fullWidth placeholder=" Email Address..." />
                    </div>
                    <div>
                        <Button fullWidth>Proceed</Button>
                    </div>
                </div>
            </Box>
            <Footer />
        </>
    )
}

export default LoginStep1
