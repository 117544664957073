import React from "react";
import "../microInfluencer/MicroInflu.css";
function MicroInfluencer() {
  return (
    <>
      <div className="microInfluencer">
        <div className="mainWapperMicro">
          <div className="heroImg">
            <img
              src="https://www.influglue.com/public/frontend/images/influncer/Janvi.jpg?v=1688734414"
              alt="heroimg"
            />
          </div>
          <div className="contentblock">
            <h2>Become a Micro-Influencer and monetize your Social Presence</h2>
            <p>
              Get started with the most promising technology-driven influencer
              marketing platform in India.
            </p>
            <a href="/">Sign up as Influencer</a>
          </div>
        </div>
      </div>
    </>
  );
}
export default MicroInfluencer;
