// import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "../sliderHome/SliderHome.css";
import br1 from '../../assest/images/abarisInfluencebr1.jpg'
import br2 from '../../assest/images/abarisInfluencebr2.jpg'
import br3 from '../../assest/images/abarisInfluencebr3.jpg'

// import "./styles.css";

function SliderHome() {
  return (
    <>
      {" "}
      <Swiper
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
       
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        modules={[Pagination,Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="item">
            <img
              className="img-itm"
              src={br1}
              alt="imgimf"
              style={{ width: 1500 }}
            />
            <div className="mainimgwapper">
              <div className="slider-content-block">
                <span>Are You Ready To</span>
                <h2> Monetize Your Social Presence ?</h2>
                <h3>Get started with the most promising technology driven influencer marketing platform.</h3>
                <a href="/" className="bluebtn">
                  Start Now
                </a>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
         
          <div className="item">
            <img
              className="img-itm"
              src={br2}
              alt="imgimf"
              style={{ width: 1500 }}
            />
            <div className="mainimgwapper">
              <div className="slider-content-block">
                <h2>Connecting Brands with Influencers</h2>
                <h3>Unleashing the Power of Authentic Influence</h3>
                <a href="/" className="bluebtn">
                  Start Now
                </a>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="item">
            <img
              className="img-itm"
              src={br3}
              alt="imgimf"
              style={{ width: 1500 }}
            />
            <div className="mainimgwapper">
              <div className="slider-content-block">
                <h2>Drive Influence, Drive Results</h2>
                <h3>Strategic Influencer Marketing for Brands</h3>
                <a href="/" className="bluebtn">
                  Start Now
                </a>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
export default SliderHome;
