const FreqAskQuesData = [
    {
        id: 'panel1',
        question: '👉 Are celebrity endorsements the same as influencer marketing?',
        answer: 'Not at all. Influencers generally have a smaller audience in compare to celebrities. They have a particular niche and generate content on that which appeals to people with a common interest. Unlike celebrities, influencers interact with their loyal followers frequently to value their opinion.',
    },
    {
        id: 'panel2',
        question: '👉 Why should I choose an influencer marketing agency?',
        answer: 'Working with an influencer marketing agency is cost-effective and time-saving. Moreover, they will help you in selecting proper influencers who can resonate well with your venture. For every scaled business working with an influencer marketing company can innovatively increase their sales from their target location which is crucial for a sustainable business plan.'
    },
    {
        id: 'panel3',
        question: '👉 What results can I expect out of my campaign?',
        answer: 'After successful completion of your campaign you can expect: High-class visual content (promotional) for your venture,Increased footfall to your social media pages,More engagement,Increase query,Get potential customers from your target location,Better future content strategy'
    },
    {
        id: 'panel4',
        question: '👉 How to Start my first campaign?',
        answer: "It's very simple hit the ‘’Start A Campaign’’ button and fill-up the form including all the credentials of your venture. Our team will get back to you at their earliest to proceed further."
    },
    {
        id: 'panel5',
        question: '👉 How much does an influencer marketing campaign cost?',
        answer: "Our every campaign is custom made to suit every client’s marketing requirement, so it's difficult to provide a standard pricing sheet; it varies from company to company. It will fluctuate based on a number of deliverables and factors, for example, number of influencers, number of followers they have, campaign details, labor costs, digital spend to promote the campaign etc. We will provide both standard and premium packages from which you can choose anyone based on your budget."
    },
    {
        id: 'panel6',
        question: '👉 When should I make my payment?',
        answer: " After finalizing the campaign, before we start, you have to pay 50% of the entire campaign price (including GST), the rest needs to be paid after the successful completion of the campaign. There is no hidden charge."
    }, {
        id: 'panel7',
        question: '👉  How do I know which influencers are working for me?',
        answer: "We have more than 5000 influencers registered with us from different niches, upon finalizing the campaign strategy and receiving the advanced amount we will show our selected influencers whom we want to take forward your campaign. You can see the influencers, how many followers they have and every other detail."
    }, {
        id: 'panel8',
        question: '👉  May I choose influencers on my own?',
        answer: "Yes, why not. You can choose influencers on your own, after paying the advanced amount (50% of the total campaign value). We will approach those influencers for your campaign; if they accept our campaign invitation, we can proceed with them further."
    }, {
        id: 'panel9',
        question: '👉 Who will design the campaign?',
        answer: "Our team of influencer marketers will design the campaign after getting details from you. We will discuss the deliverables with you and design the end-to-end campaign."
    }, {
        id: 'panel10',
        question: '👉 How do I know my influencers are genuine?',
        answer: "That is our job to do. We use the latest tools to measure the authenticity of our influencers. We will get every detail about the influencer, from his/her number of followers, age group and location of the followers, engagement, genuine likes, quality of comment etc. We only work with authentic influencers that are famous in their particular niche."
    }
];
export default FreqAskQuesData