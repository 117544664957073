import React from "react";
import "../techBlock/TechBlock.css";
function TechBlock() {
  return (
    <>
      <div className="techblock">
        <div className="mainwappertechblock">
          <div className="innerweb">
            <div className="contentblock">
              <h2>Technology Driven Celebrities / Artists / Influencers Marketing Agency in India</h2>
              <p>
                InfluGlue helps small businesses and medium-sized brands connect
                with willing content creators and micro-Celebrities / Artists / Influencers across
                various mediums like Instagram, Facebook, Youtube, and blogs.
              </p>
            </div>
            <div className="card-block">
              <div className="block">
                <div className="circle-block">
                  <img
                    className=""
                    src="https://www.influglue.com/public/frontend/images/new_icons/influencers_icon.png?v=1688734414"
                    alt="img"
                    style={{ width: 84, height: 84 }}
                  />
                </div>
                <h3>Celebrities / Artists / Influencers</h3>
                <p>
                  Enhance your social presence by creating engaging content, get
                  exciting offers, coupons and of course real money!
                </p>
                <a className="" href="/">
                  Signup
                </a>
              </div>
              <div className="blocks">
                <div className="circle-blocks">
                  <img
                    className=""
                    src="https://www.influglue.com/public/frontend/images/new_icons/brands_icon.png?v=1688734414"
                    alt="img"
                    style={{ width: 84, height: 84 }}
                  />
                </div>
                <h3>Brands</h3>
                <p>
                  Uplift your brand value with high profile Celebrities / Artists / Influencers & get
                  amazing responses within days. We deliver measurable (ROI)
                </p>
                <a className="" href="/">
                  Signup
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="marketing">
        <div className="mainwappermark">
          <div className="marketing-holder">
            <div className="imgholder">
              <img
                className="imgg"
                src="https://www.influglue.com/public/frontend/images/new_img/pic1.jpg?v=1688734414"
                alt="marketingpic"
              />
            </div>
            <div className="content">
              <h2>How our Celebrities / Artists / Influencers marketing platform can assist brands?</h2>
              <p>
                Collaborating with top content creators in their respective
                niches along with our in-house team of expert influencer
                marketers, we design an outstanding influencer marketing
                strategy around your brand to fulfill your marketing goals. With
                our proven strategy your brand will receive the utmost
                visibility which is crucial for business success. We help
                organisations achieve their business goals and dealings through
                assured and scalable engagement.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TechBlock;
