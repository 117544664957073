import { Box, Button, TextField } from "@mui/material"
import Footer from "../common/footer/Footer"
import Header from "../common/header/Header"
import '../styles/advertiserSignupStyle.css'
import FreqAskQuestion from "../components/FreqAskQues"
import axios from "axios"
import { useEffect, useState } from "react"
import Multiselect from "multiselect-react-dropdown"
import { useNavigate } from "react-router-dom"

const AdvertiserSignUp = () => {
    const [divisionData, setDivisionData] = useState([])
    const [brandData, SetBrandData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        mobile: '',
        password: '123456',
        division: ''
    })
    const [finalCityType, setFinalCityType] = useState()
    const [token, setToken] = useState(null)
    const navigate =useNavigate()

    const GetData = async () => {
        try {
            const redDivisionData = await axios.get('https://onlineparttimejobs.in/api/division/public',{withCredentials:true})
            console.log(redDivisionData.data.name);
            setDivisionData(redDivisionData.data);
            console.log(redDivisionData.data);
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        GetData();
    }, []);

    const inputHandleChange = (e) => {
        const inputName = e.target.name;
        const inputVar = e.target.value;
        const cloned = { ...brandData }
        cloned[inputName] = inputVar;
        SetBrandData(cloned);
    }

    const submitBrand = async () => {
        try {
            console.log(brandData)
            const add_BrandDetail = await axios.post('https://onlineparttimejobs.in/api/user/register1',{...brandData, city:finalCityType});
           
            console.log(add_BrandDetail.data);
            setToken(add_BrandDetail.data.token);
            navigate('/advertiser-signup/more-details/'+ add_BrandDetail.data.token )
            
            console.log('Form data submitted successfully!');
        } catch (error) {
            console.log('Error submitting form:', error)
        }
    }

    return (
        <>
            <Header />
            <Box className='advertise-box'>
                <div>
                    <div className="sign-up-text">
                        <h1 className=" fw-bold">Sign up for your Brand Campaign</h1>
                        <h4>Sign up with Abaris for free and promote your brand.</h4>
                        <p>If you are not sure how the Influencer Marketing will work for your brand, kindly <strong style={{ color: '#595aff' }}>book a 15 mins call</strong> with our Sales Managers and they will guide you through. It's Free.</p>
                    </div>
                    <div className="input-area-div">
                        <div>
                            <TextField
                                name="firstname"
                                onChange={inputHandleChange}
                                placeholder="First Name " fullWidth />
                        </div>
                        <div>
                            <TextField
                                name="lastname"
                                onChange={inputHandleChange}
                                placeholder="Last Name " fullWidth />
                        </div>
                        <div>
                            <TextField
                                name="email"
                                onChange={inputHandleChange}
                                placeholder="Email " fullWidth />
                        </div>
                        <div>
                            <TextField
                                name="mobile"
                                onChange={inputHandleChange}
                                placeholder="Enter 10 Digit Mobile Number " fullWidth helperText='Note: Mobile number should not include +91'
                            />
                        </div>
                        <div style={{ marginBottom: "13px", textAlign: 'start' }}>
                            <label className="m-2" htmlFor="flexRadioDefault1"> Zone <span style={{ color: 'red' }}>*</span></label>
                            <Multiselect
                                name='division'
                                onChange={inputHandleChange}
                                isObject={true}
                                displayValue="title"
                                options={divisionData}
                                showCheckbox
                                selectedValues={[]}
                                onRemove={(selectedCat) => {
                                    const selectedIds = selectedCat.map((cat) => {
                                        return cat.uid
                                    })
                                    setFinalCityType(selectedIds)
                                }}
                                onSelect={(selectedCat) => {
                                    const selectedIds = selectedCat.map((cat) => {
                                        return cat.uid
                                    })
                                    setFinalCityType(selectedIds)
                                }}
                            />
                        </div>
                        <div className="advertise-btn-div">
                            <Button type="button" onClick={submitBrand}>proceed</Button>
                            
                        </div>
                    </div>
                </div>
            </Box>
            <FreqAskQuestion />
            <Footer />
        </>
    )
}

export default AdvertiserSignUp
