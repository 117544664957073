import React, { useEffect, useState } from "react";
import "../header/Header.css";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";
import logo from '../../assest/images/infulogo.jfif'
import axios from "axios";
import { useTranslation } from 'react-i18next';

function Header() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [language, setLanguage] = useState([])
  const [defaultLanguage, setDefaultLanguage] = useState([])
  const [changeLanguage, setChangeLanguage] = useState([])


  const getLanguageData = async () => {
    const reqLangData = await axios.get("https://onlineparttimejobs.in/api/language", {
      withCredentials: true
    });
    console.log("language", reqLangData.data)
    setLanguage(reqLangData.data)
  }
  const getDefaultLang = async () => {
    const reqLangDefault = await axios.get("https://onlineparttimejobs.in/api/settings/language", {
      withCredentials: true
    });
    console.log(" default language", reqLangDefault.data)
    setDefaultLanguage(reqLangDefault.data.language)
  }

  useEffect(() => {
    getLanguageData();
    getDefaultLang();
  }, []);

  const onLanguageChange = (e) => {
    const langId = e.target.id;
    changeLanguageHandle(e.target.value)
    const langVar = e.target.value;
    const cloned = { ...changeLanguage }
    cloned[langId] = langVar;
    setChangeLanguage(cloned);
    console.log("scccdc")
  }

  const changeLanguageHandle = async (lang) => {
    const reqChangeLang = await axios.put("https://onlineparttimejobs.in/api/settings/change/language", { id: lang }, {
      withCredentials: true
    });
    console.log("change language", reqChangeLang.data)
    setChangeLanguage(reqChangeLang.data)
    setDefaultLanguage(reqChangeLang.data.language_id)
    if((reqChangeLang.data.language_id)=='6572d09f96580ddb0fff28b2'){
      changeLan('arabic')
    }
    else{
      console.log('dfsvfdv')
      changeLan('en')
    }
    // window.location.reload();
    console.log((reqChangeLang.data.language_id)=='6572d09f96580ddb0fff28b2');
  }
  const changeLan = async (lng) => {
    i18n.changeLanguage(lng);
    //set current language in local storage
    localStorage.setItem('lang',lng)
    console.log('lng',lng)
  };

  useEffect(()=>{
    //get the current language stored in local storage 
    let currentLang=localStorage.getItem('lang');
    i18n.changeLanguage(currentLang)
  },[])

  return (
    <>
      <div className="topdiv-bar">
        <div>
          <select value={defaultLanguage} onChange={onLanguageChange}>
            {language?.map((itm) => {
              return (
                <option value={itm._id}>{itm.name}</option>
              )
            })}
          </select>
        </div>
      </div>
      <div className="nav">
        <div className="nav-bar">
          <div className="logo">
            <Link to={'/'}>
              <img
                className="ms-4"
                src={logo}
                alt="logo"
                style={{ width: '80px', height: '80px' }}
              />
            </Link>
          </div>
          <div className="nav-list">
            <ul>
              <li>
                <Link to="/our-influencers">{t('header_key')}</Link>
              </li>
              <li>
                <Link to="/service">{t('header_key1')}</Link>
              </li>
              <li>
                <Link to="/advertiser-signup">{t('header_key2')}</Link>
              </li>
              <li className="price-li">
                <a href="/">
                  {t('header_key3')}
                  <span className="spnarrow">
                    <MdKeyboardArrowDown />
                  </span>
                </a>
                <div className="hide-price">
                  <ul>
                    <li><Link to={'/instagram-plan'}>Instagram Packages</Link></li>
                    <li><Link to={'/youtube-plan'}>Youtube Packages</Link></li>
                    <li><Link to={'/guest-posts'}>Guest Post Packages</Link></li>
                  </ul>
                </div>
              </li>
              <li>
                <Link to="/campaigns"> {t('header_key4')}</Link>
              </li>
              <li>
                <Link to="/blog"> {t('header_key5')}</Link>
              </li>
              <li>
                <Link to="/contact">{t('header_key6')}</Link>
              </li>
              <li>
                <Link to="/sign-in" className="login">
                {t('header_key7')}
                </Link>
              </li>
              <li>
                <Link to="/sign-up" className="signup">
                {t('header_key8')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
export default Header;
