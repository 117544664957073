import React from "react";
import "./HowItsWork.css";
import AdvantagesBlock from "../../components/advantagesBlock/AdvantagesBlock";
import Header from "../../common/header/Header";
import Footer from "../../common/footer/Footer";

function HowItsWork() {
  return (
    <>
    <Header/>
      <div className="Howits">
        <div className="breadcurmb">
          <div className="mainwapperabout">
            <a href="/">Home</a>
            <span>»</span>
            How it Works
          </div>
        </div>
        <div className="banner">
          <div className="bannerinnerblock">
            <img
              className=""
              src="https://www.influglue.com/public/frontend/images/howitworkbg.jpg?v=1688734414"
              alt="banner"
            />
          </div>
          <div className="mainWapperClearFix">
            <div className="textBlock">
              <h2 className="white">How Abaris Works for Influencers</h2>
              <p className="white">
                Elevate Your Online Presence With Immediate Access To Live
                Campaigns And Get Paid To Promote Awesome Brands.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="howitstepsblock">
        <div className="mainwapperhowit">
          <div className="howitiblock">
            <h2>How It Works</h2>
            <ul className="howitprogressbar">
              <li className="active activeBorder">
                <div className="arrow1"></div>
                Sign Up
              </li>
              <li>
                <div className="arrow2"></div>
                Sart Campaigns
              </li>
              <li>
                <div className="arrow3"></div>
                Go Social
              </li>
              <li>
                <div className="arrow4"></div>
                Get Paid
              </li>
            </ul>
            <a href="/" className="bluebtn">
              Sign Up As an INFLUENCER
            </a>
          </div>
          <hr />
          <div className="twohow">
            <div className="imgblock">
              <img
                className=""
                src="https://www.influglue.com/public/frontend/images/influencers.jpg?v=1688734414"
                alt="img"
              />
            </div>
            <div className="influcontent">
              <h2>
                How Abaris Works
                <br />
                For Brands
              </h2>
              <ul className="dotsitem">
                <li className="listhowit" style={{ listStyle: "disc" }}>
                  A Diverse Network
                </li>
                <li className="listhowit" style={{ listStyle: "disc" }}>
                  An Intelligent Platform
                </li>
              </ul>
              <p>
                Powerful influencers act as an extension of your brand for a
                more impactful experience. With various influencers, you can
                stimulate the audience by shifting their mindset.
              </p>
              <ul className="pointsnumb">
                <li className="pintnbr1">
                  Sign Up with us to unlock endless offers and benefits.
                </li>
                <li className="pintnbr2">
                  Sign Up with us to unlock endless offers and benefits.
                </li>
                <li className="pintnbr3">
                  Sign Up with us to unlock endless offers and benefits.
                </li>
              </ul>
              <a href="/" className="bluebtn">
                Signup for your campaign
              </a>
            </div>
          </div>
        </div>
      </div>
      <AdvantagesBlock />
      <Footer/>
    </>
  );
}
export default HowItsWork;
