const OurinfluencerData = [
    {
        id: 1,
        name: 'BUSHRA',
        city: 'Delhi',
        categories: ['Health', 'Lifestyle', 'Parenting', 'Education', 'Food', 'Mom'],
        followers: '35.6K',
        posts: '1.3K',
        engagement: '1.03%',
        imageUrl:'https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414'
    }, {
        id: 2,
        name: 'KRITIKA',
        city: 'Delhi',
        categories: ['Beauty', 'Lifestyle', "Women's Fashion", 'Travel', 'Entertainments'],
        followers: '1.8M',
        posts: '6.4K',
        engagement: '2.63%',
        imageUrl:'https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414'
    }, {
        id: 3,
        name: 'SANDY',
        city: 'Bangalore',
        categories: ['Couple', 'Lifestyle', 'Travel', 'Photography', 'Food'],
        followers: '39.6K',
        posts: '654',
        engagement: '0.53%',
        imageUrl:'https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414'
    },
    {
        id: 4,
        name: 'PRERNA',
        city: 'Mumbai',
        categories: ["Women's Fashion", 'Lifestyle', 'Parenting', 'Travel', 'Fitness', 'Mom'],
        followers: '35.6K',
        posts: '1.3K',
        engagement: '1.03%',
        imageUrl:'https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414'
    }, {
        id: 5,
        name: 'Deepa',
        city: 'Bangalore',
        categories: ['Health', 'Lifestyle', 'Parenting', 'Education', 'Food', 'Mom'],
        followers: '35.6K',
        posts: '1.3K',
        engagement: '1.03%',
        imageUrl:'https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414'
    },
    {
        id: 6,
        name: 'DIMPLE',
        city: 'Mumbai',
        categories: ['Health', 'Lifestyle', 'Beauty', 'Travel', 'Mom'],
        followers: '35.6K',
        posts: '1.3K',
        engagement: '1.03%',
        imageUrl:'https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414'
    },
];
export default OurinfluencerData;