import React from "react";
import "../footer/Footer.css";
import { FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <>
      
      <div className="footer">
        <div className="top-footer-block">
          <div className="container mainwap">
            <div className="first-block">
              <h2>Check It All</h2>
              <ul>
                <li>
                  <Link className="" to="/how-its-work">
                    <FaChevronRight /> How it works
                  </Link>
                </li>
                <li>
                  <Link className="" to="/about">
                    <FaChevronRight /> About us
                  </Link>
                </li>
                <li>
                  <Link className="" to="/service">
                    <FaChevronRight /> Services
                  </Link>
                </li>
                <li>
                  <Link className="" to="/campaigns">
                    <FaChevronRight /> Campaigns
                  </Link>
                </li>
                <li>
                  <Link className="" to="/our-client">
                    <FaChevronRight /> Our Clients
                  </Link>
                </li>
                <li>
                  <Link className="" to="/testimonials">
                    <FaChevronRight />
                    Testimonials
                  </Link>
                </li>
                <li>
                  <Link className="" to="/contact">
                    <FaChevronRight />
                    Contact us
                  </Link>
                </li>
                <li>
                  <Link className="" to="/jobs">
                    <FaChevronRight /> Jobs
                  </Link>
                </li>
                <li>
                  <Link className="" to="/faq">
                    <FaChevronRight /> FAQs
                  </Link>
                </li>
                <li>
                  <Link className="" to="/blog">
                    <FaChevronRight /> Blog
                  </Link>
                </li>
              </ul>
            </div>
            <div className="second-block">
              <h2> Abaris Influence Marketing Agency</h2>
              <p>
                98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends
                Colony,
                <br />
                New Delhi-110025 India
              </p>
              <div className="block">
                <h2>Contact Now</h2>
                <a href="/">+91 88517 46286</a>
              </div>
              <div className="block">
                <h2>Drop an Email</h2>
                <a href="/">seoabaris@gmail.com</a>
              </div>
            </div>
            <div className="first-block">
              <h2>Case Studies</h2>
              <ul>
                <li>
                  <a className="" href="/">
                    <FaChevronRight /> PharmEasy
                  </a>
                </li>
                <li>
                  <a className="" href="/">
                    <FaChevronRight />
                    Sesa Care
                  </a>
                </li>
                <li>
                  <a className="" href="/">
                    <FaChevronRight />
                    Supply6
                  </a>
                </li>
                <li>
                  <a className="" href="/">
                    <FaChevronRight />
                    Nirakle
                  </a>
                </li>
                <li>
                  <a className="" href="/">
                    <FaChevronRight />
                    GetMega App
                  </a>
                </li>
                <li>
                  <a className="" href="/">
                    <FaChevronRight /> Edvoy
                  </a>
                </li>
                <li>
                  <a className="" href="/">
                    <FaChevronRight /> Bidding Mart
                  </a>
                </li>
                <li>
                  <a className="" href="/">
                    <FaChevronRight /> Nestle Gerber
                  </a>
                </li>
                <li>
                  <a className="" href="/">
                    <FaChevronRight /> LunchBoss
                  </a>
                </li>
                <li>
                  <a className="" href="/">
                    <FaChevronRight /> Hi9
                  </a>
                </li>
                <li>
                  <a className="" href="/">
                    <FaChevronRight /> McDowells
                  </a>
                </li>
              </ul>
            </div>
            <div className="fourth-block">
              <h2>About Us</h2>
              <p>
                Abaris Influence Marketing is one of the leading influencer
                marketing platforms in India that works with top influencers in
                different sectors including fashion, health and fitness, travel,
                food and many more. We provide brands and agencies with
                measurable engagement opportunities.
              </p>
              <div className="block">
                <h2>Follow Us on</h2>
                <div className="inner-social">
                  <a className="" href="/">
                    <img
                      className=""
                      src="https://www.influglue.com/public/frontend/images/facebook_btn.png?v=1688734414"
                      alt="facebook"
                      style={{ width: 32, height: 32 }}
                    />
                  </a>
                  <a className="" href="/">
                    <img
                      className=""
                      src="https://www.influglue.com/public/frontend/images/linkedin_btn.png?v=1688734414"
                      alt="linkedin"
                      style={{ width: 32, height: 32 }}
                    />
                  </a>
                  <a className="" href="/">
                    <img
                      className=""
                      src="https://www.influglue.com/public/frontend/images/instagram_btn.png?v=1688734414"
                      alt="instagram"
                      style={{ width: 32, height: 32 }}
                    />
                  </a>
                  <a className="" href="/">
                    <img
                      className=""
                      src="https://www.influglue.com/public/frontend/images/yahoo_btn.png?v=1688734414"
                      alt="youTube"
                      style={{ width: 32, height: 32 }}
                    />
                  </a>
                </div>
              </div>
              <div className="footer-company">
                <h2>
                  <a href="/" className="">
                    Abaris Softech Pvt Ltd
                  </a>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="mid-footer-block">
          <div className="mainwapper">
            <a href="/" className="fill">
              I'm an Influencer
            </a>
            <a href="/" className="outline">
              I'm a Brand
            </a>
          </div>
        </div>
        <div className="copyright-block">
          <div className="maincopyright-wapper">
            <div className="left">
              Copyright © 2019 - 2023 Abaris Softech . All Rights Reserved.
              <a href="/"> Privacy Policy </a>
              <span>|</span>
              <a href="/"> Terms & Conditions </a>
              <span>|</span>
              <a href="/"> Sitemap</a>
            </div>
            <div className="footer-logo">
              <img
                className=""
                src="https://www.abarissoftech.com/assets/img/logo-2.png"
                alt="abarislogo"
                style={{ width: 151, height: 26 }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Footer;
