import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// Import translations
import enTranslations from './locales/english/eng.json'; // English translations
import arabicTranslations from './locales/arabic/arabic.json'; // arabic translations

const resources = {
  en: {
    translation: enTranslations,
  },
  arabic: {
    translation: arabicTranslations,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // default language
    keySeparator: false, // allow using dot in keys (e.g., 'common.header')
    interpolation: {
      escapeValue: false, // not needed for React
    },
  });

export default i18n;