import React, { useEffect, useState } from "react";
import "../homeServiceBlock/HomeService.css";
import axios from "axios";
import { useTranslation } from 'react-i18next';

function HomeServiceBlock() {
  const [industry, setImdustry] = useState([])
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  useEffect(() => {
    const getIndustryData = async () => {
      const reqData = await axios.get("https://onlineparttimejobs.in/api/industry/public", {
        withCredentials: true
      });
      console.log('industry', reqData.data)
      setImdustry(reqData.data)
    }
    getIndustryData();
  }, []);
  return (
    <>
      <div className="homeserviceBlock">
        <div className="mainHomeservice">
          <div className="serviceblock">
            <h2>{t('service_heading')}</h2>
            <p>
            {t('service_paragraph')}
            </p>
            <div className="inner-service-block">
              {industry?.map((item) => {
                return (
                  <div className="block">
                    <a href="/">
                      <div className="iconblock">
                        <img
                          src={item.icon.url}
                          alt="img"
                          style={{ width: 70, height: 70 }}
                        />
                      </div>
                      <h6>{item.name}</h6>
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default HomeServiceBlock;
