import React from "react";
import "./WhatTheyDo.css";
function WhatTheydoforBrand() {
  return (
    <>
      <div className="whatTheyDo">
        <div className="blueSteps">
          <div className="bluebghalf"></div>
          <div className="mainWapperBluesteps">
            <h2>What Influencers Can Do for Your Brand?</h2>
            <div className="innerBlueStep">
              <div className="block">
                <span>01</span>
                <img src="https://www.influglue.com/files/medialibrary/63d5e3d31efd971fc6ec17025ffc6e08.png" />
                <p>
                  Create static and carousel posts on Instagram/ Facebook with
                  branded clothing, apparel, and accessories.
                </p>
              </div>
              <div className="block">
                <span>02</span>
                <img src="https://www.influglue.com/files/medialibrary/676624842912e816dfeffaf26472ab18.png" />
                <p>
                  Create Video posts on Instagram/ Facebook/ TikTok with branded
                  clothing, apparel, and accessories.
                </p>
              </div>
              <div className="block">
                <span>03</span>
                <img src="https://www.influglue.com/files/medialibrary/1f0054cb91b706224bfe403af426bb3c.png" />
                <p>
                  Buy products from your website, create an unboxing video,
                  share the post with their followers, and submit honest online
                  reviews.
                </p>
              </div>
              <div className="block">
                <span>04</span>
                <img src="https://www.influglue.com/files/medialibrary/e5b9a8cc04a02549a70b12cb4bc2c89d.png" />
                <p>
                  Physically Visit fashion stores of a selected locality and
                  create a story about the store highlighting their collection
                  and other USPs.
                </p>
              </div>
              <div className="block">
                <span>05</span>
                <img src="https://www.influglue.com/files/medialibrary/a5c26dc7dbed87c748f5bab567ac9777.png" />
                <p>
                  Create and Manage Instagram Contest (Giveaway or other) and
                  help increase Facebook and Instagram Followers of the
                  concerned brand.
                </p>
              </div>
            </div>
            <a href="" className="startbtngh">
              Start A Campaign
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhatTheydoforBrand;
