import React from "react";
import "./Jobs.css";
import Header from "../../common/header/Header";
import Footer from "../../common/footer/Footer";
function Jobs() {
  return (
    <>
    <Header/>
      <div className="jobs">
        <div className="breadcurmb">
          <div className="mainwapperabout">
            <a href="/">Home</a>
            <span>»</span>
            Jobs
          </div>
        </div>
        <div className="aboutJobsBlock">
          <div className="JobsMainWapper">
            <h1>
              Jobs
              <span>Work at Abaris Marketing Agency.</span>
            </h1>
            <div className="jobsBlock">
              <div className="block">
                <div className="leftblock">
                  <h3>Influencer Marketing Executives</h3>
                  <p>
                  Abaris is looking for experienced Influencer Marketing
                    Executives who can take care of client enquiries, discuss
                    requirements, submit proposals, negotiate with them to close
                    the contracts and then execute the campaign with selected
                    influencers. Working experience with any Influencer
                    Marketing Agency with a proven record of client
                    acquisitions, campaign execution and handling various
                    influencer collaborations and contracts are invited.
                  </p>
                  <ul>
                    <li>Sales</li>
                    <li>Digital Marketing</li>
                    <li>Influencer Marketing</li>
                    <li>Full-Time</li>
                    <li>Remote</li>
                  </ul>
                </div>
                <a href="/" className="bluebtn">
                  Apply
                </a>
              </div>
              <div className="block">
                <div className="leftblock">
                  <h3>Full Stack Developer</h3>
                  <p>
                    Candidate with experience in PHP Laravel Framework, MySQL,
                    Jquery, CSS3, HTML5. Knowledge in AngularJS and Node JS
                    preferred.
                  </p>
                  <ul>
                    <li>Engineering</li>
                    <li>Part-Time</li>
                    <li>Remote</li>
                  </ul>
                </div>
                <a href="/" className="bluebtn">
                  Apply
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}
export default Jobs;
