import Header from "../common/header/Header"
import '../styles/campaignStyle.css'
import { Box, Button } from "@mui/material"
import { IoLocationOutline } from "react-icons/io5";
import Footer from "../common/footer/Footer";
import { TfiMenuAlt } from "react-icons/tfi";
import { LuCalendarDays } from "react-icons/lu";
import { Link } from "react-router-dom";


const Campaigns = () => {
    return (
        <>
            <Header />

            <div className="Campaign-text">
                <div className="div-campaign">
                    <h2>All Recent Campaigns</h2>
                    <p>Take a look at our recent campaigns to get a better idea about how we work and with whom we partner. All our projects are backed by enormous market research to get the most out of every campaign. We maintain utmost transparency with our clients so there is no scope for false hopes or commitments.</p>
                </div>
            </div>

            <Box>
                <div className="campmainbox">
                    <div className="leftcampdiv col-sm-3">
                        <div className="filtercampdiv">
                            <h5>FILTERS</h5>
                            <img src="https://www.influglue.com/public/frontend/images/filtericon.png" alt="img" />
                        </div>
                        <div className="category-div">
                            <h5><TfiMenuAlt className=" fs-5 pe-1" />Category</h5>
                            <ul>
                                <li>Beauty</li>
                                <li>Education</li>
                                <li>Women's Fashion</li>
                                <li>Food</li>
                                <li>Lifestyle</li>
                                <li>Parenting</li>
                                <li>Photography</li>
                                <li>Travel</li>
                                <li>Technology</li>
                                <li>Couple</li>
                                <li>Men's Fashion</li>
                                <li>Mom</li>
                                <li>Fitness</li>
                                <li>Pet</li>
                            </ul>
                        </div>
                        <div className="city-campdiv">
                            <h5><IoLocationOutline className="mb-1" />City</h5>
                            <ul>
                                <li>Ahmedabad</li>
                                <li>Bangalore</li>
                                <li>Bhagalpur</li>
                                <li>Bhopal</li>
                                <li>Bhubaneswar</li>
                                <li>Chandigarh</li>
                                <li>Chennai</li>
                                <li>Coimbatore</li>
                                <li>Delhi</li>
                                <li>Durgapur</li>
                                <li>Faridabad</li>
                                <li>Ghaziabad</li>
                                <li>Gurgaon</li>
                                <li>Guwahati</li>
                                <li>Howrah</li>
                                <li>Hyderabad</li>
                                <li>Indore</li>
                                <li>Jaipur</li>
                                <li>Jamshedpur</li>
                                <li>Kanpur</li>
                                <li>Kochi</li>
                                <li>Kolkata</li>
                                <li>Lucknow</li>
                                <li>Mumbai</li>
                                <li>Nagpur</li>
                                <li>Navi Mumbai</li>
                                <li>Noida</li>
                                <li>Patna</li>
                                <li>Pune</li>
                                <li>Srinagar</li>
                                <li>Trivandrum</li>
                                <li>Vadodara</li>
                                <li>Vijayawada</li>
                                <li>Visakhapatnam</li>
                            </ul>
                        </div>

                    </div>

                    <div className="rightcampdiv col-sm-7">
                        <div className="right-camp-ad-box">
                            <div className="product-promotion">
                                <h4><Link to={'/campaign-detail'}>Veirdo Promotion</Link></h4>
                                <div>Campaign ID: 1816</div>
                            </div>

                            <div className="prod-by">
                                <ul>
                                    <li>
                                        <p className="mb-0">Posted By:</p>
                                        <img src="https://www.influglue.com/public/frontend/images/male_placeholder.png" alt="img" />
                                        <div>Ruksar Shaikh</div>
                                    </li>
                                    <li>
                                        <p className="mb-0">Posted On:</p>
                                        <LuCalendarDays  className="calander-icon"/>
                                        <div>28 Nov, 2023</div>
                                    </li>
                                    <li className="brand-manage">Brand Managed</li>
                                </ul>
                            </div>

                            <div className="needinflu-div">Need influencers for Flipkart product reviews.</div>

                            <div className="product-detail-box">
                                <ul>
                                    <li>
                                        <strong>Product Type</strong>
                                        <small>:</small>
                                        <span>Product</span>
                                    </li>
                                    <li>
                                        <strong>Brand Name</strong>
                                        <small>:</small>
                                        <span>Veirdo</span>
                                    </li>
                                    <li>
                                        <strong>Category</strong>
                                        <small>:</small>
                                        <span>Men's Fashion</span>
                                    </li>
                                    <li>
                                        <strong>Influencer Needed</strong>
                                        <small>:</small>
                                        <span>5 - 10</span>
                                    </li>
                                    <li>
                                        <strong>Payment Option</strong>
                                        <small>:</small>
                                        <span>Open for Negotiations</span>
                                    </li>
                                    <li>
                                        <strong>Expected Start</strong>
                                        <small>:</small>
                                        <span>Within 2 weeks</span>
                                    </li>
                                    <li>
                                        <strong>Application Count</strong>
                                        <small>:</small>
                                        <span>7 influencers applied</span>
                                    </li>
                                    <li>
                                        <strong>Application Remaining</strong>
                                        <small>:</small>
                                        <span>53</span>
                                    </li>
                                    <li>
                                        <strong>Apply Within</strong>
                                        <small>:</small>
                                        <span>13 Dec, 2023</span>
                                    </li>
                                </ul>
                            </div>

                            <div className="camp-btn">
                                <Button className="btn1">Apply Now</Button>
                                <Button className="btn2">View Details</Button>
                            </div>
                            <div className="camp-notice">
                                <small>*Brand will shortlist profiles. Once shorlisted applicants will be able to view brand contact details.</small>
                            </div>
                        </div>

                        <div className="right-camp-ad-box">
                            <div className="product-promotion">
                                <h4>Grand Trade Fair Promotion</h4>
                                <div>Campaign ID: 1816</div>
                            </div>

                            <div className="prod-by">
                                <ul>
                                    <li>
                                        <p className="mb-0">Posted By:</p>
                                        <img src="https://www.influglue.com/public/frontend/images/male_placeholder.png" alt="img" />
                                        <div>Sampa Saha</div>
                                    </li>
                                    <li>
                                        <p className="mb-0">Posted On:</p>
                                        <LuCalendarDays  className="calander-icon"/>
                                        <div>27 Nov, 2023</div>
                                    </li>
                                    <li className="brand-manage">Brand Managed</li>
                                </ul>
                            </div>

                            <div className="needinflu-div">We need celebrity influencers to promote our brand.</div>

                            <div className="product-detail-box">
                                <ul>
                                    <li>
                                        <strong>Product Type</strong>
                                        <small>:</small>
                                        <span>Service</span>
                                    </li>
                                    <li>
                                        <strong>Brand Name</strong>
                                        <small>:</small>
                                        <span>Grand Trade Fair</span>
                                    </li>
                                    <li>
                                        <strong>Category</strong>
                                        <small>:</small>
                                        <span>Entertainments</span>
                                    </li>
                                    <li>
                                        <strong>Influencer Needed</strong>
                                        <small>:</small>
                                        <span>1 - 5</span>
                                    </li>
                                    <li>
                                        <strong>Payment Option</strong>
                                        <small>:</small>
                                        <span>Open for Negotiations</span>
                                    </li>
                                    <li>
                                        <strong>Expected Start</strong>
                                        <small>:</small>
                                        <span>Within 2 weeks</span>
                                    </li>
                                    <li>
                                        <strong>Application Count</strong>
                                        <small>:</small>
                                        <span>7 influencers applied</span>
                                    </li>
                                    <li>
                                        <strong>Application Remaining</strong>
                                        <small>:</small>
                                        <span>53</span>
                                    </li>
                                    <li>
                                        <strong>Apply Within</strong>
                                        <small>:</small>
                                        <span>13 Dec, 2023</span>
                                    </li>
                                </ul>
                            </div>

                            <div className="camp-btn">
                                <Button className="btn1">Apply Now</Button>
                                <Button className="btn2">View Details</Button>
                            </div>
                            <div className="camp-notice">
                                <small>*Brand will shortlist profiles. Once shorlisted applicants will be able to view brand contact details.</small>
                            </div>
                        </div>

                        <div className="right-camp-ad-box">
                            <div className="product-promotion">
                                <h4>MensClub Fashion Promotion</h4>
                                <div>Campaign ID: 1816</div>
                            </div>

                            <div className="prod-by">
                                <ul>
                                    <li>
                                        <p className="mb-0">Posted By:</p>
                                        <img src="https://www.influglue.com/public/frontend/images/male_placeholder.png" alt="img" />
                                        <div>Mens club Fashions</div>
                                    </li>
                                    <li>
                                        <p className="mb-0">Posted On:</p>
                                        <LuCalendarDays  className="calander-icon"/>
                                        <div>28 Nov, 2023</div>
                                    </li>
                                    <li className="brand-manage">Brand Managed</li>
                                </ul>
                            </div>

                            <div className="needinflu-div">Need men's fashion YouTube influencers based in Telangana.</div>

                            <div className="product-detail-box">
                                <ul>
                                    <li>
                                        <strong>Product Type</strong>
                                        <small>:</small>
                                        <span>Product</span>
                                    </li>
                                    <li>
                                        <strong>Brand Name</strong>
                                        <small>:</small>
                                        <span>Veirdo</span>
                                    </li>
                                    <li>
                                        <strong>Category</strong>
                                        <small>:</small>
                                        <span>Men's Fashion</span>
                                    </li>
                                    <li>
                                        <strong>Influencer Needed</strong>
                                        <small>:</small>
                                        <span>5 - 10</span>
                                    </li>
                                    <li>
                                        <strong>Payment Option</strong>
                                        <small>:</small>
                                        <span>Open for Negotiations</span>
                                    </li>
                                    <li>
                                        <strong>Expected Start</strong>
                                        <small>:</small>
                                        <span>Within 2 weeks</span>
                                    </li>
                                    <li>
                                        <strong>Application Count</strong>
                                        <small>:</small>
                                        <span>7 influencers applied</span>
                                    </li>
                                    <li>
                                        <strong>Application Remaining</strong>
                                        <small>:</small>
                                        <span>53</span>
                                    </li>
                                    <li>
                                        <strong>Apply Within</strong>
                                        <small>:</small>
                                        <span>13 Dec, 2023</span>
                                    </li>
                                </ul>
                            </div>

                            <div className="camp-btn">
                                <Button className="btn1">Apply Now</Button>
                                <Button className="btn2">View Details</Button>
                            </div>
                            <div className="camp-notice">
                                <small>*Brand will shortlist profiles. Once shorlisted applicants will be able to view brand contact details.</small>
                            </div>
                        </div>

                        <div className="right-camp-ad-box">
                            <div className="product-promotion">
                                <h4>AlgoWave Promotion</h4>
                                <div>Campaign ID: 1816</div>
                            </div>

                            <div className="prod-by">
                                <ul>
                                    <li>
                                        <p className="mb-0">Posted By:</p>
                                        <img src="https://www.influglue.com/public/frontend/images/male_placeholder.png" alt="img" />
                                        <div>Ruksar Shaikh</div>
                                    </li>
                                    <li>
                                        <p className="mb-0">Posted On:</p>
                                        <LuCalendarDays  className="calander-icon"/>
                                        <div>28 Nov, 2023</div>
                                    </li>
                                    <li className="brand-manage">Brand Managed</li>
                                </ul>
                            </div>

                            <div className="needinflu-div">We provide algorithmic, data-driven indicators for day traders and investors. We are in search of YouTube and Instagram influencers to promote our brand. Additionally, we are looking for a blogger who can create and post content about our brand and services.</div>

                            <div className="product-detail-box">
                                <ul>
                                    <li>
                                        <strong>Product Type</strong>
                                        <small>:</small>
                                        <span>Product</span>
                                    </li>
                                    <li>
                                        <strong>Brand Name</strong>
                                        <small>:</small>
                                        <span>Veirdo</span>
                                    </li>
                                    <li>
                                        <strong>Category</strong>
                                        <small>:</small>
                                        <span>Men's Fashion</span>
                                    </li>
                                    <li>
                                        <strong>Influencer Needed</strong>
                                        <small>:</small>
                                        <span>5 - 10</span>
                                    </li>
                                    <li>
                                        <strong>Payment Option</strong>
                                        <small>:</small>
                                        <span>Open for Negotiations</span>
                                    </li>
                                    <li>
                                        <strong>Expected Start</strong>
                                        <small>:</small>
                                        <span>Within 2 weeks</span>
                                    </li>
                                    <li>
                                        <strong>Application Count</strong>
                                        <small>:</small>
                                        <span>7 influencers applied</span>
                                    </li>
                                    <li>
                                        <strong>Application Remaining</strong>
                                        <small>:</small>
                                        <span>53</span>
                                    </li>
                                    <li>
                                        <strong>Apply Within</strong>
                                        <small>:</small>
                                        <span>13 Dec, 2023</span>
                                    </li>
                                </ul>
                            </div>

                            <div className="camp-btn">
                                <Button className="btn1">Apply Now</Button>
                                <Button className="btn2">View Details</Button>
                            </div>
                            <div className="camp-notice">
                                <small>*Brand will shortlist profiles. Once shorlisted applicants will be able to view brand contact details.</small>
                            </div>
                        </div>

                        <div className="right-camp-ad-box">
                            <div className="product-promotion">
                                <h4>DENZFOX Promotion</h4>
                                <div>Campaign ID: 1816</div>
                            </div>

                            <div className="prod-by">
                                <ul>
                                    <li>
                                        <p className="mb-0">Posted By:</p>
                                        <img src="https://www.influglue.com/public/frontend/images/male_placeholder.png" alt="img" />
                                        <div>Ruksar Shaikh</div>
                                    </li>
                                    <li>
                                        <p className="mb-0">Posted On:</p>
                                        <LuCalendarDays  className="calander-icon"/>
                                        <div>28 Nov, 2023</div>
                                    </li>
                                    <li className="brand-manage">Brand Managed</li>
                                </ul>
                            </div>

                            <div className="needinflu-div">Looking for male fashion YouTubers with at least 100,000 subscribers to collaborate with our men's wear brand.</div>

                            <div className="product-detail-box">
                                <ul>
                                    <li>
                                        <strong>Product Type</strong>
                                        <small>:</small>
                                        <span>Product</span>
                                    </li>
                                    <li>
                                        <strong>Brand Name</strong>
                                        <small>:</small>
                                        <span>DENZFOX</span>
                                    </li>
                                    <li>
                                        <strong>Category</strong>
                                        <small>:</small>
                                        <span>Men's Fashion</span>
                                    </li>
                                    <li>
                                        <strong>Influencer Needed</strong>
                                        <small>:</small>
                                        <span>5 - 10</span>
                                    </li>
                                    <li>
                                        <strong>Payment Option</strong>
                                        <small>:</small>
                                        <span>Open for Negotiations</span>
                                    </li>
                                    <li>
                                        <strong>Expected Start</strong>
                                        <small>:</small>
                                        <span>Within 2 weeks</span>
                                    </li>
                                    <li>
                                        <strong>Application Count</strong>
                                        <small>:</small>
                                        <span>7 influencers applied</span>
                                    </li>
                                    <li>
                                        <strong>Application Remaining</strong>
                                        <small>:</small>
                                        <span>53</span>
                                    </li>
                                    <li>
                                        <strong>Apply Within</strong>
                                        <small>:</small>
                                        <span>13 Dec, 2023</span>
                                    </li>
                                </ul>
                            </div>

                            <div className="camp-btn">
                                <Button className="btn1">Apply Now</Button>
                                <Button className="btn2">View Details</Button>
                            </div>
                            <div className="camp-notice">
                                <small>*Brand will shortlist profiles. Once shorlisted applicants will be able to view brand contact details.</small>
                            </div>
                        </div>

                        <div className="right-camp-ad-box">
                            <div className="product-promotion">
                                <h4>Denta Mitra Promotion</h4>
                                <div>Campaign ID: 1816</div>
                            </div>

                            <div className="prod-by">
                                <ul>
                                    <li>
                                        <p className="mb-0">Posted By:</p>
                                        <img src="https://www.influglue.com/public/frontend/images/male_placeholder.png" alt="img" />
                                        <div>Ruksar Shaikh</div>
                                    </li>
                                    <li>
                                        <p className="mb-0">Posted On:</p>
                                        <LuCalendarDays  className="calander-icon"/>
                                        <div>28 Nov, 2023</div>
                                    </li>
                                    <li className="brand-manage">Brand Managed</li>
                                </ul>
                            </div>

                            <div className="needinflu-div">Promote our USP and brand</div>

                            <div className="product-detail-box">
                                <ul>
                                    <li>
                                        <strong>Product Type</strong>
                                        <small>:</small>
                                        <span>Service</span>
                                    </li>
                                    <li>
                                        <strong>Brand Name</strong>
                                        <small>:</small>
                                        <span>Denta Mitra</span>
                                    </li>
                                    <li>
                                        <strong>Category</strong>
                                        <small>:</small>
                                        <span>Health</span>
                                    </li>
                                    <li>
                                        <strong>Influencer Needed</strong>
                                        <small>:</small>
                                        <span>5 - 10</span>
                                    </li>
                                    <li>
                                        <strong>Payment Option</strong>
                                        <small>:</small>
                                        <span>Open for Negotiations</span>
                                    </li>
                                    <li>
                                        <strong>Expected Start</strong>
                                        <small>:</small>
                                        <span>Within 2 weeks</span>
                                    </li>
                                    <li>
                                        <strong>Application Count</strong>
                                        <small>:</small>
                                        <span>7 influencers applied</span>
                                    </li>
                                    <li>
                                        <strong>Application Remaining</strong>
                                        <small>:</small>
                                        <span>53</span>
                                    </li>
                                    <li>
                                        <strong>Apply Within</strong>
                                        <small>:</small>
                                        <span>13 Dec, 2023</span>
                                    </li>
                                </ul>
                            </div>

                            <div className="camp-btn">
                                <Button className="btn1">Apply Now</Button>
                                <Button className="btn2">View Details</Button>
                            </div>
                            <div className="camp-notice">
                                <small>*Brand will shortlist profiles. Once shorlisted applicants will be able to view brand contact details.</small>
                            </div>
                        </div>

                        <div className="right-camp-ad-box">
                            <div className="product-promotion">
                                <h4>Veirdo Promotion</h4>
                                <div>Campaign ID: 1816</div>
                            </div>

                            <div className="prod-by">
                                <ul>
                                    <li>
                                        <p className="mb-0">Posted By:</p>
                                        <img src="https://www.influglue.com/public/frontend/images/male_placeholder.png" alt="img" />
                                        <div>Ruksar Shaikh</div>
                                    </li>
                                    <li>
                                        <p className="mb-0">Posted On:</p>
                                        <LuCalendarDays  className="calander-icon"/>
                                        <div>28 Nov, 2023</div>
                                    </li>
                                    <li className="brand-manage">Brand Managed</li>
                                </ul>
                            </div>

                            <div className="needinflu-div">Need influencers for Flipkart product reviews.</div>

                            <div className="product-detail-box">
                                <ul>
                                    <li>
                                        <strong>Product Type</strong>
                                        <small>:</small>
                                        <span>Product</span>
                                    </li>
                                    <li>
                                        <strong>Brand Name</strong>
                                        <small>:</small>
                                        <span>Veirdo</span>
                                    </li>
                                    <li>
                                        <strong>Category</strong>
                                        <small>:</small>
                                        <span>Men's Fashion</span>
                                    </li>
                                    <li>
                                        <strong>Influencer Needed</strong>
                                        <small>:</small>
                                        <span>5 - 10</span>
                                    </li>
                                    <li>
                                        <strong>Payment Option</strong>
                                        <small>:</small>
                                        <span>Open for Negotiations</span>
                                    </li>
                                    <li>
                                        <strong>Expected Start</strong>
                                        <small>:</small>
                                        <span>Within 2 weeks</span>
                                    </li>
                                    <li>
                                        <strong>Application Count</strong>
                                        <small>:</small>
                                        <span>7 influencers applied</span>
                                    </li>
                                    <li>
                                        <strong>Application Remaining</strong>
                                        <small>:</small>
                                        <span>53</span>
                                    </li>
                                    <li>
                                        <strong>Apply Within</strong>
                                        <small>:</small>
                                        <span>13 Dec, 2023</span>
                                    </li>
                                </ul>
                            </div>

                            <div className="camp-btn">
                                <Button className="btn1">Apply Now</Button>
                                <Button className="btn2">View Details</Button>
                            </div>
                            <div className="camp-notice">
                                <small>*Brand will shortlist profiles. Once shorlisted applicants will be able to view brand contact details.</small>
                            </div>
                        </div>

                        <div className="right-camp-ad-box">
                            <div className="product-promotion">
                                <h4>Grand Trade Fair Promotion</h4>
                                <div>Campaign ID: 1816</div>
                            </div>

                            <div className="prod-by">
                                <ul>
                                    <li>
                                        <p className="mb-0">Posted By:</p>
                                        <img src="https://www.influglue.com/public/frontend/images/male_placeholder.png" alt="img" />
                                        <div>Sampa Saha</div>
                                    </li>
                                    <li>
                                        <p className="mb-0">Posted On:</p>
                                        <LuCalendarDays  className="calander-icon"/>
                                        <div>27 Nov, 2023</div>
                                    </li>
                                    <li className="brand-manage">Brand Managed</li>
                                </ul>
                            </div>

                            <div className="needinflu-div">We need celebrity influencers to promote our brand.</div>

                            <div className="product-detail-box">
                                <ul>
                                    <li>
                                        <strong>Product Type</strong>
                                        <small>:</small>
                                        <span>Service</span>
                                    </li>
                                    <li>
                                        <strong>Brand Name</strong>
                                        <small>:</small>
                                        <span>Grand Trade Fair</span>
                                    </li>
                                    <li>
                                        <strong>Category</strong>
                                        <small>:</small>
                                        <span>Entertainments</span>
                                    </li>
                                    <li>
                                        <strong>Influencer Needed</strong>
                                        <small>:</small>
                                        <span>1 - 5</span>
                                    </li>
                                    <li>
                                        <strong>Payment Option</strong>
                                        <small>:</small>
                                        <span>Open for Negotiations</span>
                                    </li>
                                    <li>
                                        <strong>Expected Start</strong>
                                        <small>:</small>
                                        <span>Within 2 weeks</span>
                                    </li>
                                    <li>
                                        <strong>Application Count</strong>
                                        <small>:</small>
                                        <span>7 influencers applied</span>
                                    </li>
                                    <li>
                                        <strong>Application Remaining</strong>
                                        <small>:</small>
                                        <span>53</span>
                                    </li>
                                    <li>
                                        <strong>Apply Within</strong>
                                        <small>:</small>
                                        <span>13 Dec, 2023</span>
                                    </li>
                                </ul>
                            </div>

                            <div className="camp-btn">
                                <Button className="btn1">Apply Now</Button>
                                <Button className="btn2">View Details</Button>
                            </div>
                            <div className="camp-notice">
                                <small>*Brand will shortlist profiles. Once shorlisted applicants will be able to view brand contact details.</small>
                            </div>
                        </div>

                        <div className="right-camp-ad-box">
                            <div className="product-promotion">
                                <h4>MensClub Fashion Promotion</h4>
                                <div>Campaign ID: 1816</div>
                            </div>

                            <div className="prod-by">
                                <ul>
                                    <li>
                                        <p className="mb-0">Posted By:</p>
                                        <img src="https://www.influglue.com/public/frontend/images/male_placeholder.png" alt="img" />
                                        <div>Mens club Fashions</div>
                                    </li>
                                    <li>
                                        <p className="mb-0">Posted On:</p>
                                        <LuCalendarDays  className="calander-icon"/>
                                        <div>28 Nov, 2023</div>
                                    </li>
                                    <li className="brand-manage">Brand Managed</li>
                                </ul>
                            </div>

                            <div className="needinflu-div">Need men's fashion YouTube influencers based in Telangana.</div>

                            <div className="product-detail-box">
                                <ul>
                                    <li>
                                        <strong>Product Type</strong>
                                        <small>:</small>
                                        <span>Product</span>
                                    </li>
                                    <li>
                                        <strong>Brand Name</strong>
                                        <small>:</small>
                                        <span>Veirdo</span>
                                    </li>
                                    <li>
                                        <strong>Category</strong>
                                        <small>:</small>
                                        <span>Men's Fashion</span>
                                    </li>
                                    <li>
                                        <strong>Influencer Needed</strong>
                                        <small>:</small>
                                        <span>5 - 10</span>
                                    </li>
                                    <li>
                                        <strong>Payment Option</strong>
                                        <small>:</small>
                                        <span>Open for Negotiations</span>
                                    </li>
                                    <li>
                                        <strong>Expected Start</strong>
                                        <small>:</small>
                                        <span>Within 2 weeks</span>
                                    </li>
                                    <li>
                                        <strong>Application Count</strong>
                                        <small>:</small>
                                        <span>7 influencers applied</span>
                                    </li>
                                    <li>
                                        <strong>Application Remaining</strong>
                                        <small>:</small>
                                        <span>53</span>
                                    </li>
                                    <li>
                                        <strong>Apply Within</strong>
                                        <small>:</small>
                                        <span>13 Dec, 2023</span>
                                    </li>
                                </ul>
                            </div>

                            <div className="camp-btn">
                                <Button className="btn1">Apply Now</Button>
                                <Button className="btn2">View Details</Button>
                            </div>
                            <div className="camp-notice">
                                <small>*Brand will shortlist profiles. Once shorlisted applicants will be able to view brand contact details.</small>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>

            <Footer />
        </>
    )
}

export default Campaigns
