import React from "react";
import "./AbouUs.css";
import Header from "../../common/header/Header";
import Footer from "../../common/footer/Footer";
function AboutUs() {
  return (
    <>
    <Header/>
      <div className="aboutus">
        <div className="breadcurmb">
          <div className="mainwapperabout">
            <a href="/">Home</a>
            <span>»</span>
            About Us
          </div>
        </div>
        <div className="termblock">
          <div className="mainwapperterm">
            <h1>About Us</h1>
            <h4>
              “You do not need a million followers to mark your influence, but
              you must understand what makes your customers tick."
            </h4>
            <p>
              Abaris Influencer Marketing is one of the leading influencer
              marketing platforms in India that works with top influencers in
              different sectors including fashion, health and fitness, travel,
              food and many more. We provide brands and agencies with measurable
              engagement opportunities.{" "}
            </p>
            <p>
              This is the most secure platform to connect brands and
              influencers. We help brands create engaging content and endorse
              them through influential personalities resonating well with the
              brand identity.
            </p>
            <p>
              We also connect businesses with content curators from all over the
              country which keeps your brand ahead. We work with all sized
              industry to help them leverage social insights to improve business
              decisions.{" "}
            </p>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}
export default AboutUs;
