import React from "react";
import "../influncerMarkrtingBlog/Influencermarketingblock.css";
function InfluencerMarketing() {
  return (
    <>
      <div className="influencermarketingblock">
        <div className="back">
          <div className="mainwapperInfluncermarketing">
            <h2>Celebrities / Artists / Influencers Marketing Agency in India</h2>
            <p>
              InfluGlue, one of the top and the fastest growing Celebrities / Artists / Influencers
              marketing agencies in India, connects brands with appropriate
              bloggers, Celebrities / Artists / Influencers, and industry experts to create top-notch
              digital content.
            </p>
          </div>
        </div>
        <div className="mainwapperInfluncermarketing">
          <div className="ulk">
            <ul>
              <li>
                <div className="small-circle">
                  <img
                    className=""
                    src="https://www.influglue.com/public/frontend/images/new_icons/signup.png?v=1688734414"
                    alt="img"
                  />
                </div>
                <h3>Sign Up</h3>
                <p>
                  Create a brand campaign within 10 minutes. Tell us your target
                  segments, preferences and budget
                </p>
              </li>
              <li>
                <div className="small-circle">
                  <img
                    className=""
                    src="https://www.influglue.com/public/frontend/images/new_icons/shortlist.png?v=1688734414"
                    alt="img"
                  />
                </div>
                <h3>Shortlist</h3>
                <p>
                  We will filter and shortlist few best influencers and creators
                  for your brand who can get maximum exposures
                </p>
              </li>
              <li>
                <div className="small-circle">
                  <img
                    className=""
                    src="https://www.influglue.com/public/frontend/images/new_icons/monitor.png?v=1688734414"
                    alt="img"
                  />
                </div>
                <h3>Monitor</h3>
                <p>
                  Within few days influencers will showcase those contents to
                  their social media profiles and share with their followers
                </p>
              </li>
              <li>
                <div className="small-circle">
                  <img
                    className=""
                    src="https://www.influglue.com/public/frontend/images/new_icons/done.png?v=1688734414"
                    alt="img"
                  />
                </div>
                <h3>Done</h3>
                <p>
                  We will coordinate and monitor everything to make sure your
                  brand campaign becomes a hit and prepare final report
                </p>
              </li>
            </ul>
            <a href="/" className="">
              {" "}
              Start a Influencer Marketing Campaign
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
export default InfluencerMarketing;
