import { Button } from "@mui/material"
import Footer from "../common/footer/Footer"
import Header from "../common/header/Header"
import '../styles/signUpStyle.css'
import { Link } from "react-router-dom"


const SignUp = () => {
    return (
        <>
            <Header />
            <div className="signup-top-div">
                <div>
                    <h1>LET'S GET STARTED</h1>
                    <h5>Let's grow hand in hand, sign up with us to start your first campaign.</h5>
                </div>
            </div>

            <div className="signup-maindiv">
                <div className="brand-influencer">
                    <div>
                        <img src="https://www.influglue.com/public/frontend/images/influencericon.png?v=1688734414" alt="img" />
                    </div>
                    <h2>Sign up as an influencer</h2>
                    <p>Be a part of our platform and upscale your social score by stimulating your audience. Set new trends and share your story to create genuine bonds with your followers.</p>
                    <Link to={'/influencer-signup'}>
                        <Button variant="contained">Sign up</Button>
                    </Link>
                </div>
                <div className="brand-influencer">
                    <div>
                        <img src="https://www.influglue.com/public/frontend/images/advertiser-icon.png?v=1688734414" alt="img" />
                    </div>
                    <h2>Sign up as a brand</h2>
                    <p>Get connected with the most technology-driven influencer marketing platform. We deliver long term campaign-specific results, thereby ensuring your growth in no time.</p>
                    <Link to={'/advertiser-signup'}>
                    <Button variant="contained">Sign up</Button>
                    </Link>
                </div>
            </div>
            <div className="already-signin">
                <Link to={'/sign-in'}>
                   <p>Already have an account ? Login</p>
                </Link>
            </div>
            <Footer />
        </>
    )
}

export default SignUp
