import React from "react";
import "../youtuberBlock/Youtubeer.css";
function YouTuberBlock() {
  return (
    <>
      <div className="youtuberBlock">
        <div className="mAinwapperYputuber">
          <h2>Top YouTubers in India</h2>
          <p>
            Hire the top YouTubers of the country who are considered as game
            changers because of their huge influence over your target audience
            when it comes to endorsing a product or service.
          </p>
          <ul className="cards">
            <li>
              <a href="/">
                <div className="circle">
                  <img
                    className=""
                    src="https://www.influglue.com/files/medialibrary/47f2e3e3485beff4041e9277082ae026.png"
                    alt="f"
                    style={{ width: 50, height: 50 }}
                  />
                </div>
                <h4>Beauty </h4>
                <span className="spninf">YouTube Influencers</span>
              </a>
            </li>
            <li>
              <a href="/">
                <div className="circle">
                  <img
                    className=""
                    src="https://www.influglue.com/files/medialibrary/68fc27a0d21c40b8761e27997975fe59.png"
                    alt="f"
                    style={{ width: 50, height: 50 }}
                  />
                </div>
                <h4>EDUCATION </h4>
                <span className="spninf">YouTube Influencers</span>
              </a>
            </li>
            <li>
              <a href="/">
                <div className="circle">
                  <img
                    className=""
                    src="https://www.influglue.com/files/medialibrary/844eec72788a6481016babd403b3389f.png"
                    alt="f"
                    style={{ width: 50, height: 50 }}
                  />
                </div>
                <h4>WOMEN'S FASHION </h4>
                <span className="spninf">YouTube Influencers</span>
              </a>
            </li>
            <li>
              <a href="/">
                <div className="circle">
                  <img
                    className=""
                    src="https://www.influglue.com/files/medialibrary/d6e3b292d9a953761987b5d353c53709.png"
                    alt="f"
                    style={{ width: 50, height: 50 }}
                  />
                </div>
                <h4>FOOD</h4>
                <span className="spninf">YouTube Influencers</span>
              </a>
            </li>
            <li>
              <a href="/">
                <div className="circle">
                  <img
                    className=""
                    src="https://www.influglue.com/files/medialibrary/d403271eb4f8c6ca89dca559ae48e5a1.png"
                    alt="f"
                    style={{ width: 50, height: 50 }}
                  />
                </div>
                <h4>GAMING</h4>
                <span className="spninf">YouTube Influencers</span>
              </a>
            </li>
            <li>
              <a href="/">
                <div className="circle">
                  <img
                    className=""
                    src="https://www.influglue.com/files/medialibrary/8f8b6e4256475946870bbea0b71135c8.png"
                    alt="f"
                    style={{ width: 50, height: 50 }}
                  />
                </div>
                <h4>LIFESTYLE</h4>
                <span className="spninf">YouTube Influencers</span>
              </a>
            </li>
            <li>
              <a href="/">
                <div className="circle">
                  <img
                    className=""
                    src="https://www.influglue.com/files/medialibrary/c7ffab862683ba4a506f083168e0afba.png"
                    alt="f"
                    style={{ width: 50, height: 50 }}
                  />
                </div>
                <h4>TRAVEL</h4>
                <span className="spninf">YouTube Influencers</span>
              </a>
            </li>
            <li>
              <a href="/">
                <div className="circle">
                  <img
                    className=""
                    src="https://www.influglue.com/files/medialibrary/60c847864b895f313881ecf459026a71.png"
                    alt="f"
                    style={{ width: 50, height: 50 }}
                  />
                </div>
                <h4>TECHNOLOGY</h4>
                <span className="spninf">YouTube Influencers</span>
              </a>
            </li>
            <li>
              <a href="/">
                <div className="circle">
                  <img
                    className=""
                    src="https://www.influglue.com/files/medialibrary/ee8fe4782c0e9069284022b292745d32.png"
                    alt="f"
                    style={{ width: 50, height: 50 }}
                  />
                </div>
                <h4>MEN's FASHION</h4>
                <span className="spninf">YouTube Influencers</span>
              </a>
            </li>
            <li>
              <a href="/">
                <div className="circle">
                  <img
                    className=""
                    src="https://www.influglue.com/files/medialibrary/92c91f7fc1951234b2d7cb754c3b5b96.png"
                    alt="f"
                    style={{ width: 50, height: 50 }}
                  />
                </div>
                <h4>FITNESS</h4>
                <span className="spninf">YouTube Influencers</span>
              </a>
            </li>
            <li>
              <a href="/">
                <div className="circle">
                  <img
                    className=""
                    src="https://www.influglue.com/files/medialibrary/23b7b512ff59e7fd069b162045b8a28a.png"
                    alt="f"
                    style={{ width: 50, height: 50 }}
                  />
                </div>
                <h4>COMEDY</h4>
                <span className="spninf">YouTube Influencers</span>
              </a>
            </li>
            <li>
              <a href="/">
                <div className="circle">
                  <img
                    className=""
                    src="https://www.influglue.com/files/medialibrary/df9bc3739d6c71e4c5c490b696cdc909.png"
                    alt="f"
                    style={{ width: 50, height: 50 }}
                  />
                </div>
                <h4>VLOGS</h4>
                <span className="spninf">YouTube Influencers</span>
              </a>
            </li>
          </ul>
          <a href="/" className="bigbtn">
            Start a YouTube Marketing Campaign
          </a>
        </div>
      </div>
    </>
  );
}
export default YouTuberBlock;
