import React from "react";
import "../advantagesBlock/AdvtagesBlock.css";
function AdvantagesBlock() {
  return (
    <>
      <div className="advblock">
        <h2>Advantages</h2>
        <div className="twoblock">
          <div className="block">
            <h3>Brands</h3>
            <ul className="textpoint">
              <li className="textpointlist">
                <div className="iconholder">
                  <img
                    className=""
                    src="https://www.influglue.com/public/frontend/images/icon1.png?v=1688734414"
                    alt="img"
                  />
                </div>
                <div className="textHolder">
                  <h4>Amplify your online presence</h4>
                  <p>
                    Boost your social presence with engaging content and
                    influencing personalities. Resonate with your target group
                    by establishing an emotional bond.
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="block">
            <h3>Brands</h3>
            <ul className="textpoint">
              <li>
                <div className="iconholder">
                  <img
                    className=""
                    src="https://www.influglue.com/public/frontend/images/icon1.png?v=1688734414"
                    alt="img"
                  />
                </div>
                <div className="textHolder">
                  <h4>Amplify your online presence</h4>
                  <p>
                    Boost your social presence with engaging content and
                    influencing personalities. Resonate with your target group
                    by establishing an emotional bond.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
export default AdvantagesBlock;
