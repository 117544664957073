import { Favorite, FavoriteBorder } from "@mui/icons-material";
import Header from "../common/header/Header"
import '../styles/ourInfluencerStyle.css'
import { Box, Button, Checkbox } from "@mui/material"
// import { IoLocationOutline } from "react-icons/io5";
import Footer from "../common/footer/Footer";
import { Link } from "react-router-dom";
import FreqAskQues from "../components/FreqAskQues";
// import OurinfluencerData from "../data/OurInfluencerData";
// import CityNamesData from "../data/CityNamesData";
import { useEffect, useState } from "react";
import axios from "axios";
import { IoMdArrowDropdown } from "react-icons/io";

const OurInfluencers = () => {
    const [allCategory, setAllCategory] = useState([])
    const [banner, setBanner] = useState([])
    const [readZoneData, setReadZoneData] = useState([])
    const [industry, setImdustry] = useState([])
    const [country, setCountry] = useState([])
    const [influencer, setInfluencer] = useState([])

    useEffect(() => {
        const getAllCategoryData = async () => {
            const reqAllCatgData = await axios.get("https://onlineparttimejobs.in/api/category/public", {
                withCredentials: true
            });
            console.log(reqAllCatgData.data)
            setAllCategory(reqAllCatgData.data)
        }
        getAllCategoryData();
    }, []);

    useEffect(() => {
        const getBannerData = async () => {
            const reqAllBannerData = await axios.get("https://onlineparttimejobs.in/api/banner/public", {
                withCredentials: true
            });
            console.log(reqAllBannerData.data)
            setBanner(reqAllBannerData.data)
        }
        getBannerData();
    }, []);

    const GetZoneData = async () => {
        try {
            const redZoneData = await axios.get('https://onlineparttimejobs.in/api/division/public', { withCredentials: true })
            console.log(redZoneData.data.name);
            setReadZoneData(redZoneData.data);
            console.log(redZoneData.data);
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        GetZoneData();
    }, []);

    useEffect(() => {
        const getIndustryData = async () => {
            const reqData = await axios.get("https://onlineparttimejobs.in/api/industry/public", {
                withCredentials: true
            });
            console.log(reqData.data)
            setImdustry(reqData.data)
        }
        getIndustryData();
    }, []);

    useEffect(() => {
        const getCountryData = async () => {
            const reqCountryData = await axios.get("https://onlineparttimejobs.in/api/country", {
                withCredentials: true
            });
            console.log(reqCountryData.data)
            setCountry(reqCountryData.data)
        }
        getCountryData();
    }, []);


    useEffect(() => {
        const getInfluencerData = async () => {
            const reqAllInfluencerData = await axios.get("https://onlineparttimejobs.in/api/sellerList/public", {
                withCredentials: true
            });
            console.log("influencer data", reqAllInfluencerData.data)
            setInfluencer(reqAllInfluencerData.data)
        }
        getInfluencerData();
    }, []);

    const toggleCategories = () => {
        var categoryList = document.getElementById('categoryList');
        categoryList.style.display = (categoryList.style.display === 'none') ? 'block' : 'none';
    }
    const toggleZone = () => {
        var zoneList = document.getElementById('zoneList');
        zoneList.style.display = (zoneList.style.display === 'none') ? 'block' : 'none';
    }

    const toggleIndustry = () => {
        var industryList = document.getElementById('industryList');
        industryList.style.display = (industryList.style.display === 'none') ? 'block' : 'none';
    }
    return (
        <>
            <Header />
            {banner?.map((item) => {
                return (
                    <div className="influencer-top-div" style={{ backgroundImage: `url(${item.image.url})` }}>
                        <div>
                            <h1>{item.SliderTopHeading}</h1>
                            <h5>{item.bottomText}</h5>
                        </div>
                    </div>
                )
            })}

            <div className="homectgry">
                <div className="homectgMainwapper">
                    <h2>Join India's Famous Creator Community</h2>
                    <p>Sign up with us and become a part of India's most promising influencer marketing platform. Unlock various coupons, vouchers, and earn for sharing what you love.</p>
                    <div className="inner-ctg">
                        {allCategory.map((item) => (
                            <div key={item.id} className="block">
                                <a href="/">
                                    <img
                                        className=""
                                        src={item.icon.url}
                                        alt={item.name}
                                        style={{ width: 65, height: 65 }}
                                    />
                                    <h6>{item.name}</h6>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Box>
                <div className=" d-flex justify-content-evenly">
                    <div className="leftsidediv col-sm-2">
                        <div className="filterdiv">
                            <h5>FILTERS</h5>
                            <img src="https://www.influglue.com/public/frontend/images/filtericon.png" alt="img" />
                        </div>

                        <div className="city-div">
                            <div onClick={toggleCategories} className="clickdiv">
                                <h6>category</h6>
                                <IoMdArrowDropdown />
                            </div>
                            <ul id="categoryList" class="category-list">
                                {allCategory.map((items) => (
                                    <li key={items}>{items.name}</li>
                                ))}
                            </ul>
                        </div>

                        <div className="city-div">
                            <div onClick={toggleZone} className="clickdiv">
                                <h6>Zone</h6>
                                <IoMdArrowDropdown />
                            </div>
                            <ul id="zoneList" class="zone-list">
                                {readZoneData.map((div) => (
                                    <li key={div}>{div.title}</li>
                                ))}
                            </ul>
                        </div>

                        <div className="city-div">
                            <div className="clickdiv">
                                <h6>Celebrities / Artists </h6>
                                <IoMdArrowDropdown />
                            </div>
                        </div>
                        <div className="city-div">
                            <div className="clickdiv">
                                <h6>Fee Price </h6>
                                <IoMdArrowDropdown />
                            </div>
                        </div>
                        <div className="city-div">
                            <div className="clickdiv">
                            <h6>By Name A to Z  </h6>
                                <IoMdArrowDropdown />
                            </div>
                        </div>
                        <div className="city-div">
                            <div className="clickdiv">
                                <h6>By Gender  </h6>
                                <IoMdArrowDropdown />
                            </div>
                            <ul>
                                <li>Male</li>
                                <li>Female</li>
                            </ul>
                        </div>
                        <div className="city-div">
                            <div  onClick={toggleIndustry} className="clickdiv">
                            <h6>By Industry  </h6>
                                <IoMdArrowDropdown />
                            </div>
                            <ul id="industryList" class="industry-list">
                                {industry.map((itm) => (
                                    <li key={itm}>{itm.name}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="city-div">
                            <div className="clickdiv">
                                <h6>By Country</h6>
                                <IoMdArrowDropdown />
                            </div>
                            <ul>
                                {country.map((itm) => (
                                    <li key={itm}>{itm.name}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="city-div">
                            <div className="clickdiv">
                                <h6>By Celebrity Grade</h6>
                                <IoMdArrowDropdown />
                            </div>
                        </div>
                    </div>

                    <div className="rightsidediv col-sm-9">
                        <div className=" d-flex flex-wrap">
                            {influencer && influencer?.map((item) => (
                                <div key={item._id} className="card-box">
                                    <Link to={`/influencer-detail/${item._id}/${item.firstname}`}>
                                        <div className='card-img-div1'>
                                            <img src={'https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414'} alt="img" />
                                            <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                                        </div>
                                    </Link>
                                    <div className="card-content-div">
                                        <h6>{item.firstname} {item.lastname}</h6>
                                        <p>Delhi</p>
                                        <div>
                                            {item?.category?.map((category) => (
                                                <Button key={category} size="small" variant="outlined">
                                                    {category}
                                                </Button>
                                            ))}
                                        </div>
                                        <div>
                                            <ul>
                                                <li>
                                                    <p>{65}</p>
                                                    <span>Followers</span>
                                                </li>
                                                <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                                    <p>{'1.2K'}</p>
                                                    <span>Posts</span>
                                                </li>
                                                <li>
                                                    <p>{'1.2%'}</p>
                                                    <span>Engagement</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Box>

            <div className="btn-div-bottom">
                <Link to={'/influencer-signup'}>
                    <Button>
                        SIGN UP AS AN INFLUENCER
                    </Button>
                </Link>
            </div>
            <FreqAskQues />
            <Footer />

        </>
    )
}

export default OurInfluencers

